import * as Yup from 'yup';

/* Special validation
 * Import named constant and add to Yup schema like:
 * schedule: Yup.mixed().validActivitySchedule('This is just not acceptable')
 */
export const ActivityScheduleValidation = (
  Yup.addMethod(Yup.mixed, 'validActivitySchedule', function (message) {
    return this.test(
      'test-name',
      message,
      (schedule) => {
        const bad = schedule.filter((item) => {
          const from = new Date(item.from);
          const to = new Date(item.to);
          if (from >= to) return item;
        });
        return !bad.length;
      },
    );
  })
);

export const ChannelRouteValidation = (
  Yup.addMethod(Yup.mixed, 'validChannelRoutes', function (message) {
    return this.test(
      'test-name',
      message,
      (channels) => {
        const bad = channels.filter((channel) => (!!((!channel.distribution || !channel.margin))));
        return !bad.length;
      },
    );
  })
);

// Just import and use in your schema like:
// my_json_field:JSONValidation
export const JSONValidation = Yup.string()
  .test('json_field', 'Please enter a well-formed JSON payload', (str) => {
    if (!str || str.trim() === '') {
      return true;
    }
    try {
      return !!(JSON.parse(str) && !!str);
    } catch (e) {
      return false;
    }
  })
  .nullable();

// Import and use in your schema like:
//    phone:PhoneNumberValidation.nullable()
//    phone:PhoneNumberValidation.required('Give me number')
// Used in several places. For rules, we only have the field if the order product is calls.
// (product is provided in context)
export const PhoneNumberValidation = Yup.string().trim()
  .test('phone', 'Enter a 10 digit phone number prefixed with +1, or leave blank', function (phone) {

    if (!phone) {
      return true;
    }

    if (this.options.context.product && this.options.context.product !== 'calls') {
      // where product is defined in context, only validate for calls
      return true;
    }

    return /^\+1\d{10,}/.test(phone)

    // Note:masking on the input field prevents non-digit characters except "()-", and +1 at start
   // phone = phone.replace(/^\+1/, '');
   // phone = phone.replace(/[^\d]/g, '');
   // return phone.length >= 10;

  });

export const PixelValidation = Yup.array().ensure().of(Yup.string().ensure().trim().test(
  'pixel',
  'All tracking pixels require a value',
  (pixel) =>
    // Using this test so we don't get indexed pathnames like "impression_urls[2]" which are difficult to work with in current error display
    (pixel ? true : new Yup.ValidationError(['All tracking pixels require a value'], '', 'impression_urls', 'type'))
  ,
));

export const RoutingCapsValidation = Yup.addMethod(Yup.mixed, 'validRoutingCaps', function (message) {
  return this.test(
    'routing-caps',
    message,
    function (caps) {
      // just doing a check for existing values since form should restrict value entry
      return Object.keys(caps).every(k => !!(k.trim())) && Object.values(caps).every(v => !isNaN(parseInt(v)) && parseInt(v) > -1);
    },
  );
});

export const PingDedupeCapsValidation = Yup.addMethod(Yup.mixed, 'validPingDedupeCaps', function (message) {
  return this.test(
    'ping-dedupe-caps',
    message,
    function (caps) {
      // just doing a check for existing values since form should restrict value entry
      return caps.every(cap => cap.key_expression && !isNaN(parseInt(cap.duration)) && parseInt(cap.duration) > -1);
    },
  );
});

// Import this constant and use in your schema like:
// match_conditions: Yup.mixed().validTargeting('This targeting misses the mark'),
export const TargetingValidation = (
  Yup.addMethod(Yup.mixed, 'validTargeting', function (message) {
    return this.test(
      'test-name',
      message,
      function (match_conditions) {
        let errors = {};
        const attributes = this.options.context.hasOwnProperty('attributes') ? this.options.context.attributes : {};

        match_conditions.map((group, groupIndex) => {
          group.map((target, targetIndex) => {
            // {attribute: "state", matches: true, accept_unknown: false, value: Array(1)}
            const attribute = target.attribute ? attributes[target.attribute] : null;
            try {
              TargetingSchema.validateSync(target, { abortEarly: false, stripUnknown: true, context: { attribute } });
            } catch (err) {
              /* Err is ValidationError
                                errors: (2) ["value field must have at least 1 items", "Please make a selection for attribute value"]
                                inner: Array(2)
                                    0: ValidationError {name: "ValidationError", value: Array(0), path: "value", type: "min", errors: Array(1), …}
                                    1: ValidationError {name: "ValidationError", value: Array(0), path: "value", type: "required", errors: Array(1), …}

                             */
              let attrErrors = null;
              if (err.inner) {
                // Key by target attribute (state, driver.age, etc) or default to path (for example, attribute field is not set)
                attrErrors = err.inner.reduce((formError, innerError) => ({
                  ...formError,
                  [(target.attribute || innerError.path)]: innerError.message,
                }), {});
              }
              if (attrErrors) {
                errors = { ...errors, ...attrErrors };
              }
            }
          });
        });
        // console.log('CustomValidation.js:TargetingValidation errors: ', errors); // example: {attribute: "A target has no selected attribute"}

        // errors array will be assigned to the key defined by this.path, which is the parent key for the validated field.
        // For example, if parent validation schema uses this validation function with key 'match_conditions' like so:
        //    match_conditions: Yup.mixed().validTargeting('Invalid Targeting')
        // then the error object will have {match_conditions: {attribute:"bad"}}
        return Object.keys(errors).length ? new Yup.ValidationError(errors, '', this.path, 'type') : true;
      },
    );
  })
);

const doValidation = (schema, input) => {
  let errors = {};
  try {
    schema.validateSync(input, { abortEarly: false, stripUnknown: true });
  } catch (err) { // ValidationError
    if (err.inner) {
      errors = err.inner.reduce((formError, innerError) => ({
        ...formError,
        [(innerError.path)]: innerError.message,
      }), {});
    }
  }

  return errors;
};

// Import this constant and use in your schema like:
// proxy_bidding: Yup.mixed().validProxyBidding('Invalid proxy bidding configuration'),
export const ProxyBiddingValidation = Yup.addMethod(Yup.mixed, 'validProxyBidding', function (message) {
  return this.test(
    'proxy-bidding',
    message,
    function (proxy) {
      // allow pass for empty object which is default when no configuration exists
      if (!proxy || Object.keys(proxy).length === 0) return true;
      const errors = doValidation(ProxyBiddingSchema, proxy);
      return Object.keys(errors).length ? new Yup.ValidationError(errors, 'proxy_bidding', this.path, 'type') : true;
    },
  );
});

// Import this constant and use in your schema like:
// prefill: Yup.mixed().validPrefill('Invalid prefill configuration'),
export const PrefillValidation = Yup.addMethod(Yup.mixed, 'validPrefill', function (message) {
  return this.test(
    'prefill',
    message,
    function (prefill) {
      // allow pass for empty object which is default when no configuration exists
      if (!prefill || Object.keys(prefill).length === 0) return true;
      const errors = doValidation(PrefillSchema, prefill);
      return Object.keys(errors).length ? new Yup.ValidationError(errors, 'prefill', this.path, 'type') : true;
    },
  );
});

const IntegrationSchema = Yup.object().shape({
  name: Yup.string().required('Integration service is required'),
});

const ProxyBiddingSchema = Yup.object().shape({
  integration: IntegrationSchema,
  vendor_attributes: JSONValidation,
  // validate bid_floor, bid_modifier?
});

const PrefillSchema = Yup.object().shape({
  integration: IntegrationSchema,
  // template ??
});

// Use in your schema like:
// enrichment_addons: Yup.mixed().validSalesConditions('Invalid Sales Conditions'),
// note: the error is returned with key 'sales_conditions'
export const SaleConditionsValidation = (
  Yup.addMethod(Yup.mixed, 'validSaleConditions', function (message) {
    return this.test(
      'test-sale-conditions',
      message,
      function (sales_conditions) {
        const errors = {};
        const attributes = this.options.context.hasOwnProperty('attributes') ? this.options.context.attributes : {};

        sales_conditions.map((sc, index) => {
          try {
            SaleConditionsSchema.validateSync(sc, { abortEarly: false, stripUnknown: true, context: { attributes } });
          } catch (err) { // ValidationError
            let attrErrors = null;
            if (err.inner) {
              // Key by target attribute (state, driver.age, etc) or default to path (for example, attribute field is not set)
              attrErrors = err.inner.reduce((formError, innerError) => ({
                ...formError,
                [(innerError.path)]: innerError.message,
              }), {});
            }
            if (attrErrors) {
              // errors = {...errors, ...attrErrors};
              // key by index so we know which one errored
              errors[index] = { ...attrErrors };
            }
          }
        });

        // console.log('CustomValidation.js:SalesConditionsValidation errors: ', errors);

        /* Example, sales condition with no selected integration, one suppression condition without attribute selection
         {
           enrichment_addons: {  <-- this key will be the path provided, this.path
              integration: "A sales condition has no selected integration"
              suppression_conditions: {attribute: "A target has no selected attribute"}
           }
          }
         */

        return Object.keys(errors).length ? new Yup.ValidationError(errors, '', this.path, 'type') : true;
      },
    );
  })
);

const SaleConditionsSchema = Yup.object().shape({
  integration: Yup.object().shape({
    name: Yup.string().required('Integration service is required'),
  }),
  // timing: Yup.string().required('A sale condition has no selected timing'),
  suppression_conditions: Yup.mixed().validTargeting('Invalid Targeting'),
  vendor_attributes: JSONValidation,
  notify: Yup.boolean,
});

// Use in your schema like:
// enrichment_addons: Yup.mixed().validChannelModifiers('Invalid Channel Modifiers'), dsd
export const ChannelModifiersValidation = (
  Yup.addMethod(Yup.mixed, 'validChannelModifiers', function (message) {
    return this.test(
      'test-channel-modifiers',
      message,
      function (modifiers) {
        const errors = {};

        modifiers.map((sc, index) => {
          try {
            ChannelModifiersSchema.validateSync(sc, { abortEarly: false, stripUnknown: true, context: {} });
          } catch (err) { // ValidationError
            let attrErrors = null;
            if (err.inner) {
              attrErrors = err.inner.reduce((formError, innerError) => ({
                ...formError,
                [(innerError.path)]: innerError.message,
              }), {});
            }
            if (attrErrors) {
              // key by index so we know which one errored
              errors[index] = { ...attrErrors };
            }
          }
        });

        // console.log('CustomValidation.js:ChannelModifiersValidation errors: ', errors);
        return Object.keys(errors).length ? new Yup.ValidationError(errors, '', this.path, 'type') : true;
      },
    );
  })
);

// when status != disabled bid_modifiers is number
const ChannelModifiersSchema = Yup.object().shape({
  type: Yup.string(), //
  bid_modifier: Yup.mixed().when('type', { // note, error property will be 'bidding.bid_modifier'
    is: (val) => val !== 'disabled',
    then: Yup.number().transform((n) => ((isNaN(n) || n === null) ? -1 : parseInt(n))).min(0, 'Bid modifier is required').max(5000, 'Maximum bid adjustment is 5000%'),
  }),
});

// Validation schema for testing a match condition (aka targeting)
const TargetingSchema = Yup.object().shape({
  attribute: Yup.string().required('A target has no selected attribute'),
  value: Yup.mixed().when(
    // `$attribute` reference is context value provided when validating form
    '$attribute', (attribute, schema) => {
      if (attribute) {
        const attrName = attribute.label;
        switch (attribute.input_type) {
          case 'integer':
            return Yup.number()
              .transform((n) => (isNaN(n) ? null : parseInt(n)))
              .typeError(`${attrName}: Please enter a number for the value`)
              .min(0, `${attrName}:  value must be 0 or greater`)
              .required(`${attrName}: Please enter a number for the value`);
          case 'integer_range':
            // Much tricksyness
            // There may or may not be a min or max value
            // We allow either min or max not to be set, but not both unset
            // We use NumberMask in UI to ensure integer entry
            return Yup.object().transform((val) => {
              // Value might not be an object in some cases, so transform to object before validating
              // Example:  value:{} in the stored json will be sent from PHP as value:[]
              if (!val) {
                return { from: null, to: null };
              } if (Array.isArray(val)) {
                return { from: val.from || null, to: val.to || null };
              }
              return val;
            }).shape({
              from: Yup.number()
              // .typeError(`${attrName}: Please enter a number for the minimum value`)
                .transform((n) => (isNaN(n) ? null : n))
                .test('rangeMinOption', `${attrName}: The minimum range value is ${attribute.options.min}. (Leave blank to accept any value)`, (val) =>
                  // Allow not setting the value. Do comparison only if integer
                  (attribute.options.min && val !== null ? parseInt(val) >= attribute.options.min : true))
                .notRequired()
                .nullable(true),
              to: Yup.number()
              // .typeError(`${attrName}: Please enter a number for the maximum value`)
                .transform((n) => (isNaN(n) ? null : n))
                .test('rangeMaxOption', `${attrName}: The maximum range value is ${attribute.options.max}. (Leave blank to accept any value)`, (val) => (attribute.options.max && val !== null ? parseInt(val) <= attribute.options.max : true))
                .test('rangeMax', `${attrName}: Invalid range, first value must be smaller than second`, function (n) {
                  if (n === null || !Number.isInteger(parseInt(this.parent.from))) {
                    return true;
                  }
                  return n >= parseInt(this.parent.from);
                })
                .test('emptyRange', `${attrName}: You must provide one or more range values`, function (toVal) {
                  // PASS: if from or to have a val (including 0). toVal has been transfomed to null or integer
                  return toVal !== null || Number.isInteger(parseInt(this.parent.from));
                })
                .notRequired()
                .nullable(true),
            });
          case 'choice':
          case 'dynamic_choice':
            return Yup.string().required(`${attrName}: a value is required`);
          case 'text':
            return Yup.string().required(`${attrName}: a value is required`);
          case 'multiple_choice':
          case 'dynamic_multiple_choice':
          case 'metro_choice':
          case 'state_choice':
            // return Yup.array().ensure().required(`Please make a selection for attribute value`)
            return Yup.array().of(Yup.string()).required(`${attrName}: a selection is required`);
          case 'zipcode_list':
            // Might be array if user has not interacted with the field, so do transform
            return Yup.string().transform((list) => (Array.isArray(list) ? list.length ? list.join(',') : '' : list)).ensure().required('Zip Code: one or more postal codes are required');
          case 'county_list':
            return Yup.string().ensure().required('County: one or more county names are required');
        }
      }

      return Yup.mixed().notRequired();
    }),
});
