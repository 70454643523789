import React, {useState, useCallback} from 'react';
import { Button} from 'reactstrap';
import { tokenToWords } from '../../../utils/string';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import {faCode} from "@fortawesome/free-solid-svg-icons";
import { DateFormatter } from '../../../Components/Table/Table';
import { matchSorter } from 'match-sorter';
import { useDebouncedCallback } from 'use-debounce';
import {TextSearch} from "../../../Components/Table/Filter";
import {DeliveryType} from "../../../types";
import {JsonDisplay, UrlDisplayWidget, Utils, ViewSwitch, ViewSwitchButton} from "@thedmsgroup/mastodon-ui-components";
import useLocalStorage from "../../../Hooks/useLocalStorage";



type LeadDataBlock= {
  data:Record<string,any>;
  scope:string;
  delivery_id?:string;
  description?:string;
}

// Filter by "category"?
// const LeadCategoryFieldMap = {
//   location : ['city', 'state', 'zip', 'postal'],
//   connection: ['device', 'browser', 'isp', 'connection', 'isp','user_agent'],
//   completion: ['leadid_provided', 'universal_lead_id_provided', 'trusted_form_provided', 'match_chance']
// } as Record<string,string[]>

type LeadDetailProps = {
  //pii:Record<string,string>;
  lead:LeadDataBlock[];
  timezone:string;
  gotoDelivery:(id:string) => void;
  deliveries:DeliveryType[];
}

/*
 * Auction lead detail
 */
const Lead = ({
  lead,
  timezone,
  gotoDelivery,
  deliveries
}: LeadDetailProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredData, setFilteredData] = useState<LeadDataBlock[]>(lead);
  const [view, setView] = useLocalStorage("auction_detail_lead_view",'list');

  const resolveDelivery = useCallback((id:string) => {
    const dv = deliveries.find((d) => d.id === id);
    return dv ? dv.integration_info.label : 'Delivery';
  }, [deliveries])

  const handleSearchDebounced = useDebouncedCallback((term:string) => {
    setSearchTerm(term);
    if (!term) {
      setFilteredData(lead);
      return;
    }
    //Find data blocks that have a match
    const matchedRows = matchSorter(lead, term, {
          keys:[item => Object.keys(item.data).map((k) => k.replace(/_/g, ' ')) ],
          threshold: matchSorter.rankings.CONTAINS,
          sorter: (items) => items, // disabled match sorting
      })

    // reduce the properties of the matched blocks
    setFilteredData(() => {
      return matchedRows.map((block) => {
        const matchedKeys = matchSorter(Object.keys(block.data), term, {keys: [item => item.replace(/_/g, ' ')]});
        const filteredBlock = Object.fromEntries(Object.entries(block.data).filter( ([blockKey]) => matchedKeys.includes(blockKey)))
        return {...block, data:filteredBlock};
      })
    })
  }, 400);


  const handleClearSearch = () => {
    setSearchTerm("");
    setFilteredData(lead);
  }
  return (
    <div className="lead-data">

      <div className="above-table-filters d-flex align-items-center mb-3">

          <ViewSwitch currentView={view} onClick={setView} size="sm">
            <ViewSwitchButton view="list" >
              <FaIcon icon="list" size="sm"/> List
            </ViewSwitchButton>
            <ViewSwitchButton  view="json" >
              <FaIcon icon={faCode} size="sm"/> JSON
            </ViewSwitchButton>
          </ViewSwitch>

        {view === 'list' && (
            <div className="ms-2 boxed-filter new-boxed-filter search-filter flex-fill">
              <TextSearch
                // @ts-ignore  because of react.memo
                value={searchTerm}
                placeholder="Search..."
                onUpdate={handleSearchDebounced}
                onClear={handleClearSearch}
              />
            </div>
        )}

      </div>
{/*
    For now we are not including auction.pii data here  MRK-9177
      <div className="form-section border">
        <div className="form-section-header">Pii</div>
        {pii ? (
          <>
            <div className="data-row">
              <label>Name</label>
              <div>
                {pii.first_name}
                {" "}
                {pii.last_name}
              </div>
            </div>

            <div className="data-row">
              <label>Email</label>
              <div>{pii.email}</div>
            </div>

            <div className="data-row">
              <label>Phone</label>
              <div>{pii.phone}</div>
            </div>

            <div className="data-row">
              <label>Address</label>
              <div>{pii.address}</div>
            </div>

            <div className="data-row">
                <label>Age</label>
                <div>{age || <i>unknown</i>}</div>
              </div>
          </>
        ) : (
          <i>None</i>
        )}
      </div>
*/}


      {view === 'json' ? (
        <JsonDisplay data={lead} />
      ) : (
        <>
          {filteredData.map((block, i: number) => (
            <div className="form-section border mb-2" key={i}>
              <div className="d-flex justify-content-between mb-1">
                <div className="form-section-header mb-0">{block.description ?? ""}</div>
                <div className="d-flex no-wrap">
                  {block.delivery_id && (
                    <div className="delivery-pill"><FaIcon icon="envelope" size="sm"/>{' '}
                      <Button
                        color="link"
                        className="inline"
                        onClick={()=>gotoDelivery(block.delivery_id as string)}
                        size="sm">
                        {resolveDelivery(block.delivery_id)}
                      </Button>
                    </div>
                  )}
                </div>
              </div>

              {Object.entries(block.data).map(([dataKey, dataVal], i) => (
                <div className="data-row" key={i}>
                  <label>{tokenToWords(dataKey)}</label>
                  <LeadValueDisplay data={block.data} value={dataVal} dataKey={dataKey} timezone={timezone}/>
                </div>
              ))}
            </div>
          ))}
        </>
      )}




    </div>
  )
};

type LeadValueDisplayProps = {
  data:any,
  dataKey:string,
  value:any,
  timezone:string
}

const LeadValueDisplay = ({data, dataKey, value, timezone}:LeadValueDisplayProps) => {
  if (dataKey === 'source_url') {
    return <UrlDisplayWidget title="Source URL" url={value} />
  } else if (dataKey === 'state_local_time' && data.state_timezone) {

    return <div>
      <DateFormatter value={value} format="MMM do y hh:mm:ss aaa zzz" timezone={data.state_timezone} />
      {' '}
      <small>[{value}]</small>
    </div>

  } else if (typeof value === 'object') {
    return (
      <div>
        {Array.isArray(value) ? (
          <ArrayValueDisplay data={value} name={dataKey} timezone={timezone} />
        ) : (
          <ObjectValueDisplay data={value} timezone={timezone} />
        )}
      </div>
    )
  } else if (typeof value === 'boolean') {
    return <div>{value ? 'Yes' : 'No'}</div>
  } else if (value ) {

    if (/^\d{4}-\d{2}-\d{2}T/.test(value)) {
      return (<div>
        <DateFormatter
          value={value}
          format="MMM do y hh:mm:ss aaa zzz"
          // @ts-ignore
          timezone={timezone ?? null}
        />
        {' '} <small>[{value}]</small>
        </div>)
    }

    return <div>{value.toString()}</div>
  }

  return <div>&mdash;</div>
}


/*
 * ObjectValueDisplay
 * When the value of a lead prop being processed by LeadValueDisplay is an object,
 * recursively call LeaveValueDisplay to produce a nested list
 * (also can toggle to JSON view)
 */
type ObjectValueDisplayProps = {
  data:any,
  allowJsonView?:boolean,
  name?:string;
  timezone:string
}
const ObjectValueDisplay = ({data, allowJsonView=true, name, timezone}:ObjectValueDisplayProps) => {
  const[jsonView, setJsonView] = useState(false);

  const toggleView = () => setJsonView(!jsonView);

  if (!Utils.isObject(data) || !Object.keys(data).length) {
    return <div><i>No value</i></div>
  }

  return (
    <div className="form-section border">
      {name && <div className="form-section-header">{name}</div>}
      {allowJsonView && <Button color="link" size="xs" className="inline ml-auto mb-1" onClick={toggleView} >{jsonView ? 'List':'JSON'}</Button> }
      {jsonView ? (
        <JsonDisplay data={data} scrollable={false} />
      ) : (
        <>
          {Object.entries(data).map(([k, v], i) => (
            <div className="data-row" key={i}>
              <label>{tokenToWords(k)}</label>
              <LeadValueDisplay data={data} value={v} dataKey={k} timezone={timezone}/>
            </div>
          ))}
        </>
      )}

    </div>
  )
}


/*
 * ArrayValueDisplay
 * When the value of a lead prop being processed by LeadValueDisplay is an array,
 * recursively call LeaveValueDisplay to produce an array of nested lists
 * (also can toggle to JSON view)
 */
type ArrayValueDisplayProps = {
  data:any,
  name:string;
  allowJsonView?:boolean,
  timezone:string
}
const ArrayValueDisplay = ({data, name, allowJsonView=true, timezone}:ArrayValueDisplayProps) => {
  const[jsonView, setJsonView] = useState(false);

  const toggleView = () => setJsonView(!jsonView);
  if (!Array.isArray(data ) || !data.length) {
    return <div><i>Empty</i></div>
  }
  // We're not anticipating mixed type arrays
  // Could be array of strings (search_type) or array of objects (vehicles)
  const isObjectElements = Utils.isObject(data[0]);

  return (
    <div>
      {allowJsonView && <Button color="link" size="xs" className="inline mb-1" onClick={toggleView} >{jsonView ? 'List':'JSON'}</Button> }
      {jsonView ? (
        <JsonDisplay data={data} />
      ) : (
        <>
          {isObjectElements ? (
            <>
              {data.map((item:any, i:number) => (
                <div className="form-section  mb-1 p-0" key={i}>
                  <ObjectValueDisplay data={item} name={`${tokenToWords(name)} ${i+1}`} timezone={timezone} allowJsonView={false} />
                </div>
              ))}
            </>
          ) : (
            <div>{data.join(', ')}</div>
          )}

        </>
      )}

    </div>
  )
}


export default Lead;
