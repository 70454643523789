import React, { useEffect, useReducer, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import Authorizer from '../Services/Authorizer';
import CoreAPI from '../Services/CoreAPI';
import PrivacyAPI from '../Services/PrivacyAPI';
import AccountingAPI from '../Services/AccountingAPI';
import IntegrationsAPI from '../Services/IntegrationsAPI';
import ReportingAPI from '../Services/ReportingAPI';
import MatchingAPI from '../Services/MatchingAPI';

const LOADER_MESSAGE_DEFAULT = 'Loading...';
const AppContext = React.createContext();


const AppStateReducer = (state, action) => {
  const { type, value } = action;
  switch (type) {
    case 'authorizing':
      return { ...state, isAuthorizing: true };
    case 'auth_fail':
    case 'logout':
      // Todo: auth err?
      return {
        ...state, user: null, isAuthorized: false, isAuthorizing: false, currentAccount: null, ...value,
      };
    case 'auth_ready':
      return {
        ...state, isAuthorized: false, isAuthorizing: false, authErr: '', loginAction:'', logoutAction:'', ...value,
      };
    case 'auth_success':
      return {
        ...state, isAuthorized: true, isAuthorizing: false, authErr: '', loginAction:'', logoutAction:'', ...value,
      };

    case 'show_loader':
      return { ...state, ...value };
    case 'global_modal':
      // for toggling modals that can be opened on any route (my profile, etc). Expecting string name.
      return { ...state, globalModal: value };
    case 'current_account':
      return { ...state, currentAccount: value };
    case 'update_favorite_orders':
      return { ...state, favorite_orders: value };
  }
  return state;
};

const AppProvider = ({ authUrl, version='', buildHash='', children }) => {

  const [appState, dispatch] = useReducer(
    AppStateReducer,
    {
      user: null,
      permissions: [],
      authToken: '',
      authError: '',
      verticals: [],
      isAuthorized: false,
      isAuthorizing: true, // always starts with auth check
      loader: true,
      loaderMessage: 'Loading...',
      currentAccount: null,
      globalModal: '',
      logoutAction: ''
    },
  );

  // Use a ref to current state for the postLogout and cross-tab callbacks
  const appStateRef = useRef(appState);

  const postLogout = (err, options={}) => {

    // if (options.timeout && appStateRef.current?.user?.id) {
    //   const timeoutReturn = window.location.hash.replace('#', '');
    //   if (timeoutReturn && !timeoutReturn.match(/(login|logout)/i)) {
    //     window.sessionStorage.setItem('TimeoutReturn', JSON.stringify({ [appStateRef.current.user.id]: timeoutReturn }));
    //   }
    // }

    dispatch({ type: 'logout', value: { authError: err, logoutAction: options.timeout ? 'timeout' : 'user' } });
  };

  // Keep the ref updated
  useEffect(() => {
    appStateRef.current = appState;
  }, [appState]);


  const authorizer = React.useMemo(
    () => new Authorizer(authUrl, postLogout),
    [],
  );

  const getApi = useCallback(
    (apiName) => {
      switch (apiName) {
        case 'accounting':
          return new AccountingAPI();
        case 'integrations':
          return new IntegrationsAPI();
        case 'reporting':
          return new ReportingAPI();
        case 'privacy':
          return new PrivacyAPI();
        case 'matching':
          return new MatchingAPI();
        default:
          return new CoreAPI();
      }
    },
    [],
  );

  const api = React.useMemo(() => getApi(), []);


  const handleCrossTabTokenSuccess = () => {
    if (!appStateRef.current.isAuthorized) {
      authorize()
    }
  };

  const handleCrossTabTokenFail = () => dispatch({ type: 'auth_fail' });


  // Auth check on mount
  useEffect(() => {
     window.addEventListener('Mastodon.CrossTabTokenSuccess', handleCrossTabTokenSuccess);
     window.addEventListener('Mastodon.CrossTabTokenFail', handleCrossTabTokenFail);
     authorize();

     return () => {
       window.removeEventListener('Mastodon.CrossTabTokenSuccess', handleCrossTabTokenSuccess);
       window.removeEventListener('Mastodon.CrossTabTokenFail', handleCrossTabTokenFail);
     };
  }, []);

  const authorize = async () => {
    dispatch({ type: 'authorizing' });
    const result = await authorizer.authorize();

    if (result === false) {
      dispatch({ type: 'auth_fail' });
    } else if (result === 'pending') {
      // Authorizer is checking other tabs for an authentication token.
      // Do nothing here. The result from that async check is handled by window events in Authorizer
    } else if (typeof result === 'string' && result.includes('Invalid')) {
      //API failed to authenticate user
      dispatch({ type: 'auth_fail' });
      notify(result, 'error')
    } else if (typeof result === 'object') {
      dispatch({ type: 'auth_success', value: makeAuthData(result) });
    }


  };

  // Login page passes results to app on success
  const onLogin = (result) => {
    if (result) {
      authorizer.setAuthToken(result.authorization.token, true);
      dispatch({ type: 'auth_success', value: makeAuthData(result) });
    }
  };

  const authReady = () => dispatch({ type: 'auth_ready',value:{}});

  const makeAuthData = (authResult) => {
    // extract props from auth result for user data
    const data = (({
                        account_id,
                        accounts,
                        authorization,
                        user,
                        permissions,
                        timezone,
                        verified,
                        vendor_id,
                        favorite_orders,
                      }) => ({
      account_id,
      accounts,
      authorization,
      user,
      permissions,
      timezone,
      verified,
      vendor_id,
      favorite_orders
    }))(authResult);

    data.currentAccount = authResult.accounts.find((a) => a.id === authResult.account_id);

    return data;
  };

  const refreshAuth = () => authorize();

  const logout = (message, options={}) => authorizer.logout(message, {...options, broadcast:true});


  const showLoader = (name = 'main', message = '') => {
    if (name === 'false') message = LOADER_MESSAGE_DEFAULT;
    dispatch({ type: 'show_loader', value: { loader: name, loaderMessage: message } });
  };

  const updateFavoriteOrders = (orders) => {
    dispatch({ type: 'update_favorite_orders', value: orders });
  }

  // Cache used for DynamicSingleSelect.
  // We need a better system so we can cache, for example, attributes for given product & vertical
  const getCache = (name) => appState[name] || false;
  const setCache = (name, value) => dispatch({ type: 'set_cache', value: { name, value } });

  return (
    <AppContext.Provider value={{
      ...appState,
      api, // CoreAPI class
      getApi, // For getting other API classes
      dispatch,
      showLoader,
      onLogin,
      logout,
      refreshAuth,
      authReady,
      getCache,
      setCache,
      updateFavoriteOrders,
      version,
      buildHash
    }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  authUrl: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export { AppProvider, AppContext };
