import React, {useContext, useEffect, useState} from "react";
import {Outlet, Navigate, useLocation} from "react-router-dom";
import { TimezoneContextProvider } from '@thedmsgroup/mastodon-ui-components';
import {useIdleTimer} from "react-idle-timer";
import Header from './Header'
import Footer from './Footer';
import {Navbar} from "reactstrap";
import {AppContext} from "../Providers/AppProvider";
import {singularOrPlural} from "@thedmsgroup/mastodon-ui-components/lib/utils/string";
import {Button, NavbarBrand, Spinner} from "reactstrap";
import {toast} from "react-toastify";
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import logoImage from '../images/DMS_logo_mastodon_white.svg'
import VersionChecker from "../App/VersionChecker";

const IDLE_TIMEOUT_MS = 60*60*1000;
const PROMPT_BEFORE_IDLE_MS = 120_000;

const IdlePrompt = ({onStillHere, getRemainingTime}) => {
  const [remaining, setRemaining] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div>
      {remaining <= 1 ? (
        <p>Goodbye!</p>
      ) : (
        <p>Are you still here? {remaining && <span>You will be logged out in {remaining} {singularOrPlural(remaining, 'second', 'seconds')}</span>}</p>
      )}


      <Button size="sm" className="mt-1" block onClick={onStillHere}>Yes, still working</Button>
    </div>)
    ;
};

/*
 * Template wrapper for authenticated pages
 */
export const AuthLayout = () => {
  const app = useContext(AppContext);
  const location = useLocation();

  const tz = app.user?.timezone || 'America/Los_Angeles';

  const onIdle = () => {
    toast.dismiss('timeout-toast');
    app.logout('', {timeout:true});
  }

  const onActive = () => {
    toast.dismiss('timeout-toast');
  }

  const onIdlePrompt = () => {
    notify(<IdlePrompt onStillHere={handleStillHere} getRemainingTime={getRemainingTime}/> , 'warning', { autoClose: false }, 'timeout-toast');
  }

  const { getRemainingTime, activate, isPrompted  } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt:onIdlePrompt,
    timeout:IDLE_TIMEOUT_MS,
    promptBeforeIdle:PROMPT_BEFORE_IDLE_MS,
    crossTab:true,
    syncTimers: 200,
    eventsThrottle:200,// prevent too much cpu usage for user actions
    throttle: 500 // will only call server at most every n seconds for onAction
  })


  const handleStillHere = () => {
    activate();
  }


  if (!app.isAuthorizing && !app.isAuthorized) {

      if (isPrompted()) {
        toast.dismiss('timeout-toast');
      }

      return app.logoutAction === 'user' ? (
        <Navigate to="/login" />
      ) : (
          <Navigate
            to={{
              pathname:"/login",
              search: `redir=${encodeURIComponent(location.pathname + (location.search || ""))}`

            }}
          />
      );
  }


  return (
    <>
      <TimezoneContextProvider timezone={tz}>
        {app.isAuthorizing ? (
          <header className="header-main">
            <Navbar className="main-navbar" expand="md" fixed="top">
              <NavbarBrand>
                <img src={logoImage} width="160" alt="Mastodon: powered by DMS" />
                <Spinner size="sm" color="light" className="ms-2" />
              </NavbarBrand>
            </Navbar>
          </header>
        ) : (
          <>
            <Header />
            <Outlet/>
            <Footer/>
          </>
        )}

      </TimezoneContextProvider>
      <VersionChecker />
    </>

  )

};

