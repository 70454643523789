import React, {useState} from 'react';
import PropTypes from 'prop-types';
import EntityLinks from "./EntityLinks";
import {Button, Spinner} from "reactstrap";
import classnames from 'classnames';
import useApi from "../../../Hooks/useApi";
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import EntityId from "../../../Components/Table/EntityId";
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faSkullCrossbones } from '@fortawesome/free-solid-svg-icons'

const MatchingLog = ({ auction, onShowRouting }) => {
  const orders = auction.logs?.matching?.candidates || []
  // Note: on dev and staging, the accounts and orders might not be resolved
  // because the auction data is from production.

  return <>
    <div className="form-section">
      <div className="form-section-header">Matching Decisions</div>

      <p>This auction attempted to match to the following {orders.length} orders:</p>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Account</th>
            <th>Order</th>
            <th className="text-center">Matches</th>
            <th className="text-center">Bids</th>
          </tr>
        </thead>
        <tbody>
        {orders.map((cl, i) => {
          return <tr key={i}>

            <td>{cl.account?.name ? (
              <>{cl.account?.name} <EntityId id={cl.account.id} /></>
            ) : (
              <i>unknown account</i>
              )}
            </td>

            <td>
              {cl.order?.name ? (
                  <EntityLinks
                    entityId={cl.order.id}
                    accountId={cl.account.id}
                    label={<span>{cl.order.name} <EntityId id={cl.order.id} /></span>}
                    linkPath={`/accounts/${cl.account.id}/order/${cl.order.id}/rules`}
                    routingFilter="orderId"
                    openRoutingModal={onShowRouting}
                    className="justify-content-between"
                  />
                ) : (
                  <i>unknown order</i>
                )}

            </td>

            <td className="text-center">{auction.matches?.filter(m => m.order?.id === cl.order?.id).length || 0}</td>
            <td className="text-center">{auction.matches?.filter(m => m.order?.id === cl.order?.id && m.bid).length || 0}</td>
            <td><span className="small text-center">{cl.eval_time}ms</span> <br/><CandidateLog log={cl} /></td>
          </tr>
        })}
        </tbody>

      </table>

    </div>
  </>
}

const CandidateLog = ({log}) => {
  const { api } = useApi();
  const [loading, setLoading] = useState(false);
  const [matchable, setMatchable] = useState();

  const onClick = () => loadDetails(log.matchable_id);


  const loadDetails = async (matchableId) => {
    setLoading(true)
    let result = await api.endpoints.debug.matchables(matchableId, {minimal: true})

    if (result) {
      result = result.rules[0] // this is to remove the account-level rule which currently is not being used
      adjustLog(result, log)
      setMatchable(result)
    } else {
      notify(`Unable to load matchable details: ${api.error.name}`, 'error')
    }

    setLoading(false)
  }

  return <>
    {loading && <Spinner size={"sm"} />}
    {matchable && <div className={"match-tree"}>
      <Tree node={matchable}/>
    </div>}
    {!matchable && <Button onClick={onClick} color="link" size="xs" className="text-nowrap inline">
      match log
    </Button>}
  </>
}

const adjustLog = (matchable, matchLog, onlyMatched = false) => {
  const ruleLog = matchLog.rules.find(el => el.rule_id === matchable.id)
  if (ruleLog) {
    matchable.matched = true
    Object.assign(matchable, ruleLog)
  } else {
    //matchable.rules = [] // if no match, no point in getting into children
  }
  if (matchable.rules) {
    matchable.rules.map(el => {
      adjustLog(el, matchLog)
    })
    if (onlyMatched) {
      matchable.rules = matchable.rules.filter(el => el.matched)
    }
  }
}

const Tree = ({node}) => {
  return <><span className={classnames({rule: true, matched: node.matched, 'no-match': !node.matched, blocked: node.blocked})}>{node.label ?? '-'}</span>
    {!node.blocked && <>{node.matched ? <FaIcon icon={'check'} size="sm" color={'green'} className="ms-1" title="Matched" /> : <><FaIcon size="sm" icon="ban" className="ms-1" title="rule condition did not match lead data" /> </>}</>}
    {node.blocked && <><FaIcon icon={faThumbsDown} size="sm" color={'red'} className="ms-1" /> Blocked because: {node.message}</>}
    {node.dead_end && <><FaIcon icon={faSkullCrossbones} size="sm" color={'red'} className="ms-1" /> Dead-End – missing: {node.dead_end_reasons.join(', ')}</>}
    {node.rules && !node.blocked && <ul>{node.rules?.map((rule, i) => {
      return <li key={i}><Tree node={rule} /></li>
    })}</ul>}
  </>
}

MatchingLog.propTypes = {
  auction: PropTypes.object,
  onShowRouting: PropTypes.func
}

export default MatchingLog;
