import {Modal, ModalBody, ModalHeader} from "reactstrap";
import React, {useEffect, useState} from "react";
import useApi from "../../../Hooks/useApi";
import {JsonDisplay} from "@thedmsgroup/mastodon-ui-components";

export const MatchableModal = ({ matchableId, isOpen, cancel }) => {
  const {api} = useApi()
  const [matchable, setMatchable] = useState("")

  const loadMatchable = async (id) => {
    let result = await api.endpoints.debug.matchables(id)
    setMatchable(result)
  }
  useEffect(() => {
    loadMatchable(matchableId)
  }, [matchableId]);

  return <Modal isOpen={isOpen} toggle={cancel} size="lg">
    <ModalHeader toggle={cancel}>Matchable {matchableId}</ModalHeader>
    <ModalBody>
      <JsonDisplay data={matchable} />
    </ModalBody>
  </Modal>
}
