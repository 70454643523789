import React, { useEffect, useState } from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TextSearch, StackedFilter } from '../../../Components/Table/Filter';
import { StatusChoices, ProductChoices } from '../../../Components/Table/FilterChoices';
import FilterSummaryTags from "../../../Components/Table/FilterSummaryTags";
import useApiBatch from '../../../Hooks/useApiBatch';
import RightDateAndControls from '../../../Components/Table/RightDateAndControls';
import {useFilters} from "../../../Hooks/useFilters";

const Filters = ({
  filters, onChange, onRefresh, loading = false,
}) => {
  const batcher = useApiBatch();
  const [choices, setChoices] = useState({});
  const {onChangeFilter, reset, clearFilter, clearSearch} = useFilters(filters, onChange)

  // load choices
  useEffect(() => {
    const getChoices = async () => {
      const batches = await batcher.fetch([
        { endpoint: 'vendors', action: 'list' },
        { endpoint: 'verticals', action: 'list' },
        { endpoint: 'channels', action: 'list', params: { options: 1 } },
      ]);
      if (batches && batches.length) {
        const [vendors, verticals, channels] = batches;
        setChoices({
          status: StatusChoices,
          product: ProductChoices,
          vendors: vendors.map((item) => ({ value: item.id.toString(), label: item.name })),
          vertical: verticals.map((item) => ({ value: item.id, label: item.display_name })),
          channel: channels.map((item) => ({ value: item.id, label: item.name })),

          // vendor: vendors.map( item => { return {value:item.id.toString(), label:item.name}}),
        });
      }
    };

    if (Object.keys(choices).length === 0) getChoices();
  }, []);


  return (
    <div className=" position-relative">
      <div className={classnames(['loading-overlay', { show: loading }])} />
      <div className="d-flex justify-content-between">
        <div className="status-filters d-flex">

          <div className="boxed-filter new-boxed-filter search-filter me-3">
            <TextSearch
              value={filters.search || ''}
              placeholder="Search by source name or token"
              onUpdate={(val) => onChangeFilter('search', val)}
              onClear={clearSearch}
              delay={500}
            />
          </div>

          {/* Use of  inNavbar={true} disables popper and allows application of '.dropDown' animation class to menu */}
          <UncontrolledDropdown  className="simple-filters-dropdown me-2">

            <DropdownToggle  caret className="boxed-filter new-boxed-filter filter-group-dropdown">
              <FaIcon icon="filter" size="1x" className="fa-icons" />
            </DropdownToggle>

            <DropdownMenu className="select-filters-dropdown animate dropDown">

              <div className="modal-body">
                <StackedFilter
                  placeholder="Any Vertical"
                  label="Vertical"
                  param="vertical_id"
                  options={choices.vertical || []}
                  value={filters.vertical_id || ''}
                  onChange={onChangeFilter}
                  isMulti={false}
                />

                <StackedFilter
                  placeholder="Any product"
                  label="Product"
                  param="product"
                  options={choices.product || []}
                  value={filters.product || []}
                  onChange={onChangeFilter}
                  isMulti
                />

                <StackedFilter
                  placeholder="Any channel"
                  label="Channel"
                  param="channel_id"
                  options={choices.channel || []}
                  value={filters.channel_id || []}
                  onChange={onChangeFilter}
                  isMulti={false}
                />

                <StackedFilter
                  placeholder="Any status"
                  label="Status"
                  param="status"
                  options={choices.status || []}
                  value={filters.status || []}
                  onChange={onChangeFilter}
                  isMulti
                />

              </div>

            </DropdownMenu>
          </UncontrolledDropdown>

        </div>

        <RightDateAndControls
          onChangeDate={null}
          onRefresh={onRefresh}
          onReset={reset}
        />
      </div>

      <FilterSummaryTags
        choices={choices}
        filters={filters}
        filterKeys={Object.keys(filters)}
        onRemoveFilter={clearFilter}
        onClear={reset}
        isLoading={false}
        isPendingApply={false}
      />

    </div>

  );
};

Filters.propTypes = {
  filters: PropTypes.object,
  onChange: PropTypes.func,
  onRefresh: PropTypes.func,
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
};

export default Filters;
