import React, {useState} from 'react';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { MoneyFormatter, PercentFormatter } from '../../../Components/Table/Table';
import Block from './Block';
import Bid from './Bid';
import AdDisplay from '../../AdManager/AdDisplay';
import EntityLinks from "./EntityLinks";
import EntityId from "../../../Components/Table/EntityId";

/*
 * Auction match detail
 */
const Match = ({
  match_id,
  matchable_id,
  account,
  order,
  rule,
  channel,
  product,
  bid,
  account_bid_amount,
  account_bid_explanation,
  exclusive,
  blocks,
  tags,
  buyer_name,
  broker_name,
  internal_bid,
  internal_bid_modifiers,
  match_delivery_id,
  onSelectDelivery,
  onShowRouting,
  onShowMatchable,
  auto_redirect,
}) => {

  return (
    <div className="match-container form-section">

      <div className="d-flex">
      <div className="match flex-grow-1">

        <div className="form-section-header">
          Match {match_id}
        </div>

        <div className="data-row">
          <label>Buyer</label>
          <div>{buyer_name}</div>
        </div>

        <div className="data-row">
          <label>Broker</label>
          <div>
            {match_delivery_id ? <Button
              className="inline"
              color="link"
              title={`Deliveries: ${broker_name}`}
              onClick={() => onSelectDelivery(match_delivery_id)}
            >
              {broker_name}
            </Button> : (broker_name || "-")}
          </div>
        </div>

        <div className="data-rows">

          <div className="data-row">
            <label>Account</label>
            <div>{account?.name}</div>
          </div>

          <div className="data-row">
            <label>Order</label>
            <div>
              {account && order ? (
                  <EntityLinks
                    entityId={order.id}
                    accountId={account.id}
                    label={<span>{order.name} <EntityId id={order.id}/></span>}
                    linkPath={ `/accounts/${account.id}/order/${order.id}/rules`}
                    routingFilter="orderId"
                    openRoutingModal={onShowRouting}
                  />
              ) : (
                <i>unknown order</i>
              )}

            </div>
          </div>

          <div className="data-row">
            <label>Rule</label>
            <div>
              {rule && account && (
                <>
                  <Link
                    target="_blank"
                    className="link"
                    to={{
                      pathname: `/accounts/${account.id}/order/${order.id}/rules`,
                      search: `rule=${rule.id}`,
                    }}
                    title="View rule in order rule tree"
                  >
                    {rule.label}
                  </Link> <EntityId id={rule.id} />
                </>

              )}
            </div>
          </div>

          <div className="data-row">
            <label>Channel</label>
            <div>
              {channel ? (
                  <EntityLinks
                    entityId={channel.id}
                    label={<span>{channel.name} <EntityId id={channel.id} /></span>}
                    linkPath="/publishing/channels"
                    linkSearch={`page=1&search=${channel.name}`}
                    routingFilter="channelId"
                    openRoutingModal={onShowRouting}
                  />
              ) : '-'}
            </div>
          </div>

          <div className="data-row">
            <label>Exclusive</label>
            <div>{exclusive ? 'yes' : 'no'}</div>
          </div>

          <div className="data-row">
            <label>Bid</label>
            <div>
              {account_bid_amount > 0 ? <MoneyFormatter value={account_bid_amount} /> : "-"}
              {account_bid_explanation?.length > 0 && <Explanation>
                <ul className={"list-unstyled"}>
                  {account_bid_explanation
                    .filter(reason => reason.indexOf("100%") === -1)
                    .map((reason, i) => <li key={i}>{reason}</li>)
                  }
                </ul>
              </Explanation>}
            </div>
          </div>

          {internal_bid !== account_bid_amount &&<div className="data-row">
            <label>Internal Bid</label>
            <div>
              {internal_bid > 0 ? <MoneyFormatter value={internal_bid} /> : "-"}
              {internal_bid_modifiers?.length > 0 && <Explanation>
                <ul className={"list-unstyled"}>
                  {internal_bid_modifiers
                    .map((bm, i) => <li key={i}><InternalBidModifier {...bm} key={i} /></li>)
                  }
                </ul>
              </Explanation>}
            </div>
          </div>}

          <div className="data-row">
            <label>Blocks</label>
            <div>
              { blocks && blocks.length > 0 ? (
                <>
                  {blocks.map((b, i) => <Block {...b} key={i} />)}
                </>
              ) : "-"
              }
            </div>

          </div>

          { tags && tags.length > 0 && <div className="data-row">
            <label>Tags</label>
            <div>{tags.join(', ')}</div>
          </div>}

        </div>

        <div className="data-row">
          <label>Matchable ID</label>
          <div><Button
            className="inline"
            color="link"
            title={`Matchable: ${matchable_id}`}
            onClick={() => onShowMatchable(matchable_id)}
          >
            {matchable_id}
          </Button></div>
        </div>

      </div> {/* match */}

        <div className="match-bid flex-grow-1">
          {bid ? (
            <Bid {...bid} />
          ) : (
            <div className="bid no-bid">
              <div className="form-section-header mb-0 fs-5"><FaIcon icon="hand-holding-usd" /> No Bid Offered</div>
            </div>
          )}
        </div>

      </div>

      {/* Ads are in a separate full span row because of their size */}
      {product === 'clicks' && bid && (
        <>
        <div className="data-row">
          <label>Ad</label>
          <div className="">
            <div className="ads-container">
              {bid.ad ? (
                <div>
                  <AdDisplay {...bid.ad} />
                </div>
              ) : "-"}
            </div>
          </div>
        </div>
        <div className="data-row">
          <label>Auto Redirect</label>
          <div>{bid.match.auto_redirect ? 'yes' : 'no'}</div>
        </div>
        </>
      )}

    </div>
  );
};

const Explanation = ({children}) => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  return (
    <>
      <Button onClick={handleToggle} color="link" className="inline ms-2">why?</Button>
      {show && <div className="bid-explanation">
        {children}
      </div>}
    </>
  );
};

const InternalBidModifier = ({ amount, source }) => (
  <span>
    <PercentFormatter value={amount} />
    {' '}
    {source}
  </span>
);

export default Match;
