import React, {useContext,useState, useEffect} from "react";
import {HelpPopper, StandardAlert, TabNav} from "@thedmsgroup/mastodon-ui-components";
import {
  Row,
  Col,
  Button,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  FormGroup,
  Label,
  Input,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  Table,
  TabPane,
  TabContent,
  UncontrolledButtonDropdown,
} from "reactstrap";
import PageSection from "../../../../Layout/PageSection";
import {AppContext} from "../../../../Providers/AppProvider";
import EnhancedSelect from "../../../../Components/Form/EnhancedSelect";

import {
  getPing,
  getPingPost,
  pingRequestSchema,
  ResponseScenarios,
  pingResponseSchema,
  pingPostRequestSchema
} from "./samples";

import "highlight.js/styles/foundation.min.css";
import hljs from 'highlight.js';
import {CopyButton} from "@thedmsgroup/mastodon-ui-components";
import '../../../Public/PublisherPost.scss';
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons";

const pingUrl = "https://matching.platform.ue.co/ping"
const pingPostUrl = "https://matching.platform.ue.co/pingpost/{auction_id}"
const postUrl = "https://matching.platform.ue.co/post"

const wrapSchema = (token, schema) => {
  return {
    type: "object",
    properties: {
      source_token: {
        type: "string",
        description: "The source token for this traffic type",
        example: token,
        required: true,
      },
      exclusive: {
        type: "boolean",
        description: "Whether you want only exclusive bids (true) or shared bids (false). Leave out or pass null to get both types of bids.",
        default: null,
      },
      data: { ...schema, description: "The attributes describing the lead" },
    }
  }
}

/*
 * PUBLISHER POSTING INSTRUCTIONS
 */
const PostingInstructions = ({sources, apiKey, isLoading}) => {
  const app = useContext(AppContext);
  const [activeTab, setActiveTab] = useState('ping') //ping, pingpost, direct
  const [postType, setPostType] = useState('pingpost') //pingpost, direct
  const [source, setSource] = useState();
  const [schema, setSchema] = useState();
  const [sourceOptions, setSourceOptions] = useState([]);


  // const opts = [];
  // if (sources) {
  //   opts = [];
  //   sources.map((src) => {
  //     if (src.status === 'active') {
  //       opts.push({value: src.id, label: src.name})
  //       if (!source)
  //         setSource(src)
  //     }
  //   })
  // }
  //
  useEffect(() => {
    if (sources) {
      const activeSources = sources.filter(src => src.status === 'active')

      setSourceOptions(activeSources.map(src => {
        return {value:src.id, label:src.name}
      }));
      if (activeSources.length) {
        setSource(activeSources[0]);
        setSchema(wrapSchema(activeSources[0].token, activeSources[0].schema));
      }

    }
  }, [sources])

  const togglePostType = () => {
    if (postType === 'direct') {
      setActiveTab('ping');
      setPostType('pingpost');
    } else {
      setActiveTab('direct');
      setPostType('direct');
    }
  }


  const handleSourceTokenChange = (sourceId) => {
    setSource(old => {
      const inst = sources.find(s => s.id == sourceId);
      return inst || old
    })
  }

  const exampleJsonPost = {
    source_token: source?.token ?? '',
    data: source?.schema_example ?? '',
  };

  if (!isLoading && !apiKey) {
    return <div className={"text-center p-3"}>
      <h3>Uh oh</h3>
      <p>Something went wrong. The link may be invalid.</p>
    </div>
  }

  if (isLoading) {
    return <div className="d-flex align-items-center justify-content-center" style={{height: '250px'}}>
      <div>
        <Spinner color="secondary"/>
      </div>
    </div>
  }

  return (
    <PageSection title="Publisher Posting API" className="posting-instructions">


          <Row>
            <Col sm={12} md={9} lg={7}>


             {/*{app.isAuthorized && (
                <FormGroup row >
                  <Label sm={2}>
                    Source
                  </Label>
                  <Col sm={10} >
                      <EnhancedSelect
                        onChange={handleSourceTokenChange}
                        options={sourceOptions}
                        placeholder={"Select a Source..."}
                        value={source?.id}
                        returnValueAsString={false}
                        isMulti={false}
                      />
                  </Col>
                </FormGroup>
                )}*/}


              <FormGroup row >
                <Label sm={2}>
                  Source Token
                </Label>
                <Col sm={10} className="d-flex align-items-center">
                  <Input readOnly size="sm" value={source?.token ?? ""}/>
                  <CopyButton textToCopy={source?.token} id={"id-copy-source-token"}/>
                </Col>
              </FormGroup>

              {/*{app.isAuthorized && (
                <FormGroup row >
                  <Label sm={2}>
                    API Key
                  </Label>
                  <Col sm={10} className="d-flex align-items-center">
                    <Input readOnly size="sm" value={apiKey}/>
                    <CopyButton textToCopy={source?.token} id={"copy-api-key"}/>
                  </Col>
                </FormGroup>
              )}*/}

            </Col>

          </Row>

        <hr/>

        <Row>
          <Col sm={8}>
            <h2>Input Schema</h2>
            <p>See below how to send lead information to us via API, and what to expect in return.</p>

            <h3>Headers</h3>
            <div className="mb-4">
              {/*todo: sticky table header */}
              <Table size="sm" striped hover>
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                </tr>
                </thead>
                <tbody>
                   <tr>
                     <td>Authorization</td>
                     <td>Bearer {apiKey}</td>
                   </tr>
                   <tr>
                     <td>Content-Type</td>
                     <td>application/json</td>
                   </tr>
                </tbody>
              </Table>
            </div>

            <h3>Params</h3>
            <div className="xxxoverflow-scroll">
              {/*todo: sticky table header */}
              <Schema schema={schema}/>
            </div>
          </Col>

          <Col sm={4} className="example-col">
              <Row>
                <Col className="d-flex align-items-center">
                  <div className="fw-bold me-2">PingPost or Direct Post?{' '}</div>
                  <Button
                    size="sm"
                    color={postType !== 'direct'? 'primary' : 'light' }
                    onClick={togglePostType}
                    outline={postType === 'direct'}
                  >
                    Ping/Post
                  </Button>
                  <Button
                    size="sm"
                    color={postType === 'direct' ? 'primary' : 'light' }
                    onClick={togglePostType}
                    outline={postType !== 'direct'}
                    className="ms-1"
                  >
                    Direct Post
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3">
                  <Nav tabs>
                    { postType === 'direct' ? (
                        <TabNav
                        isActive={true}
                        setActive={setActiveTab}
                        id="direct"
                        >
                          Direct Post
                        </TabNav>
                    ): (
                      <>
                        <TabNav
                          isActive={activeTab === "ping"}
                          setActive={setActiveTab}
                          id="ping"
                        >Step 1: Ping</TabNav>
                        <TabNav
                          isActive={activeTab === "pingpost"}
                          setActive={setActiveTab}
                          id="pingpost"
                        >Step 2: PingPost</TabNav>
                      </>
                      )}
                  </Nav>

                  <TabContent activeTab={activeTab}>

                    <TabPane tabId="ping">
                      <div className="d-flex align-items-center my-2 fw-bold">
                        <div className="flex-grow-1 me-2">
                          <div className="sale-step-num">1</div>
                        </div>

                        <div>
                          Ping is the first of two parts to make up a sale and should not be considered a complete transaction.
                        </div>

                      </div>

                      <div>
                        <p>
                          Ping is a secure way to offer up lead properties needed to match to advertisers, and
                          {' '} do not need to include contact or PII information to that customer.
                        </p>
                        <p>This is used to find demand for the consumer to return bids.</p>
                      </div>

                      <FormGroup  >
                        <Label>
                          Request URL
                        </Label>
                        <div  className="d-flex align-items-center">
                          <Input readOnly value={pingUrl}/>
                          <CopyButton textToCopy={pingUrl} id={"copy-ping-url"}/>
                        </div>
                      </FormGroup>

                      <hr/>

                      <PhaseExamples
                        request={getPing(source?.token, source?.schema_example)}
                        responses={ResponseScenarios.ping}
                        requestSchema={pingRequestSchema}
                        responseSchema={pingResponseSchema}
                        apiKey={apiKey}
                        url={pingUrl}
                      />

                    </TabPane>

                    <TabPane tabId="pingpost">
                      <div className="d-flex align-items-center fw-bold my-2">
                        <div className="flex-grow-1 me-2">
                          <div className="sale-step-num">2</div>
                        </div>
                        <div>A PingPost is the completion of a Ping/Post and is the final agreement of the sale.</div>
                      </div>

                      <div>
                        <p>Here you send the remaining Contact and/or PII information for a consumer.</p>
                        <p>You should expect the information to be delivered to the end buyer in near real-time, but this is not always required.</p>
                      </div>

                      <FormGroup >
                        <Label>
                          Request URL
                        </Label>
                        <div  className="d-flex align-items-center">
                          <Input readOnly value={pingPostUrl}/>
                          <CopyButton textToCopy={pingPostUrl} id={"copy-ping-post-url"}/>
                        </div>
                      </FormGroup>

                      <hr/>
                      <PhaseExamples
                        request={getPingPost(source?.schema_example)}
                        responses={ResponseScenarios.pingpost}
                        requestSchema={pingPostRequestSchema}
                        responseSchema={pingResponseSchema}
                        apiKey={apiKey}
                        url={pingPostUrl}
                        />

                    </TabPane>

                    <TabPane tabId="direct">

                      <div className="mt-2">
                        <p>A Direct Post is sending a full lead to be sold immediately.</p>
                      </div>

                      <FormGroup >
                        <Label>
                          Request URL
                        </Label>
                        <div className="d-flex align-items-center">
                          <Input readOnly value={postUrl}/>
                          <CopyButton textToCopy={postUrl} id={"copy-post-url"}/>
                        </div>
                      </FormGroup>

                      <hr/>

                      <PhaseExamples
                        request={exampleJsonPost}
                        responses={ResponseScenarios.pingpost}
                        requestSchema={pingRequestSchema}
                        responseSchema={pingResponseSchema}
                        apiKey={apiKey}
                        url={postUrl}
                        />
                    </TabPane>

                  </TabContent>
                </Col>
              </Row>
          </Col>
        </Row>

    </PageSection>
  )
};

const Schema = ({schema}) => {
  return <Table size="sm" striped hover>
    <thead>
    <tr>
      <th className="w-25">Field Name</th>
      <th className="text-center">Data Type</th>
      <th className="text-center">Required</th>
      <th>Description</th>
      <th>Example</th>
    </tr>
    </thead>
    <tbody>
    {schema && <>
      {getRows(schema.properties)}
    </>}
    </tbody>
  </Table>
};

function getRows(properties, indent = 0) {
  let out = []
  for (let key of Object.keys(properties)) {
    let field = properties[key]
    let example = field.example
    if (!example) {
      if (field.enum && field.enum.length > 0) {
        example = field.enum[0] // todo random?
      } else  if (field.type === "boolean") {
        example = "false"
      }
    }

    out.push(<tr key={key}>
      <td>{[...Array(indent)].map((e, i) => <FaIcon fixedWidth={true} icon={faLongArrowAltRight} size='1x' key={i} spacing={2} />)}{key}</td>
      <td className="text-center">{field.type}</td>
      <td className="text-center">{field.required
        ? <><FaIcon icon='check' size='1x' />{field.required_lazy && <div className="mb-1"> <HelpPopper>
            This value can be provided on the POST if it is not available at the time of the PING.
          </HelpPopper></div>}</>
        : '-'}</td>
      <td>
        {field.description}
        {field.enum?.length > 0 && <div><label>Choices:</label>
          <ul>{field.enum.map(v => <li>{v}</li>)}</ul>
        </div>}
      </td>
      <td>{example != null && (field.type === "string" ? '"'+example+'"' : example)}</td>
    </tr>)

    if (field.type === 'array' && field.items?.properties) {
      out = out.concat(getRows(field.items?.properties, indent+1))
    }
    if (field.type === 'object' && field.properties) {
      out = out.concat(getRows(field.properties, indent+1))
    }
  }

  return out
}

// Shows examples for request and response
const PhaseExamples = ({request, responses, requestSchema, responseSchema, url, apiKey}) => {
  const [phase, setPhase] = useState('request');

  return (
    <div className="phase-examples">

      <Nav>
        <NavItem>
          <NavLink active={phase === 'request'} href="#" onClick={()=>setPhase('request')}>Request</NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={phase === 'response'} href="#" onClick={()=>setPhase('response')}>Response</NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={phase}>

        <TabPane tabId="request">
          <ExampleDisplay
            defaultData={request}
            apiKey={apiKey}
            url={url}
          />

          <h3>Request Schema</h3>
          <ResponseSchema schema={requestSchema}/>
        </TabPane>

        <TabPane tabId="response">

          <ExampleDisplay
            defaultData={request}
            scenarios={responses}
            apiKey={apiKey}
            url={url}
          />

          <h3>Response Schema</h3>
          <ResponseSchema schema={responseSchema} />

        </TabPane>

      </TabContent>
    </div>
  )
}

const ResponseSchema = ({schema}) => {
  return <Table size="sm" striped hover>
    <thead>
    <tr>
      <th className="w-25">Field Name</th>
      <th className="text-center">Type</th>
      <th>Description</th>
    </tr>
    </thead>
    <tbody>
      {getResponseRows(schema.properties)}
    </tbody>
  </Table>
}

function getResponseRows(properties, indent = 0) {
  let out = []
  for (let key of Object.keys(properties)) {
    let field = properties[key]

    out.push(<tr key={key}>
      <td>{[...Array(indent)].map((e, i) => <FaIcon fixedWidth={true} icon={faLongArrowAltRight} size='1x' key={i} spacing={2} />)}{key}</td>
      <td className="text-center">{field.type}</td>
      <td>{field.description}</td>
    </tr>)

    if (field.type === 'array' && field.items?.properties) {
      out = out.concat(getResponseRows(field.items?.properties, indent+1))
    }
    if (field.type === 'object' && field.properties) {
      out = out.concat(getResponseRows(field.properties, indent+1))
    }
  }

  return out
}

// This shows request or response, but only response has scenarios
const ExampleDisplay = ({defaultData, scenarios=[], apiKey, url}) => {
  const [currentScenario, setCurrentScenario] = useState(scenarios.length ? scenarios[0] : {data: defaultData})
  const [dataView, setDataView] = useState('JSON');

  const handleChangeScenario = (scenario) => {
    if (scenario) {
      setCurrentScenario(scenario)
    }
  }

  return (
    <div>
      {scenarios?.length > 1 && (
        <div className="d-flex mb-2"  >

              {scenarios.map((sc,i) => (
                <Button
                  size="xs"
                  color={currentScenario?.label === sc.label ? 'primary' : 'light' }
                  onClick={() => handleChangeScenario(sc)}
                  outline={currentScenario?.label !== sc.label}
                  className="me-1"
                  key={i}
                >
                  {sc.label}
                </Button>
              ))}
        </div>
      )}

      {currentScenario?.description && <div>{currentScenario.description}</div>}

      <div className="mb-1 text-end">
        <UncontrolledButtonDropdown >
          <DropdownToggle color="transparent" size="xs" className="inline" caret>
            {dataView}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={()=>setDataView('JSON')}>JSON</DropdownItem>
            <DropdownItem onClick={()=>setDataView('Curl')}>Curl</DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>

      {currentScenario ? (
        <>
          {dataView === 'JSON' ? (
            <JsonExample data={currentScenario.data } scrollable/>
          ) : (
            <CurlExample json={currentScenario.data} apiKey={apiKey} url={url} />
          )}
        </>
      ) : (
        <p><i>No data example found</i></p>
      )}

    </div>
  )
}

const JsonExample = ({data, scrollable}) => {
  if (!data) return <></>

  // todo: scrollable
  if (data instanceof Object)
    data = JSON.stringify(data, null, 2)

  return (
    <>
    <pre>
      <CopyButton className="float-end" textToCopy={data} id={"id-json-example"}/>
      <div className="hljs p-2" dangerouslySetInnerHTML={{
        __html: hljs.highlight(data, {language: 'json'}).value
      }}>
      </div>
  </pre>
    </>
  );
};

const curlExample = (json, apiKey, url) => `curl \\
  --header 'Authorization: Bearer ${apiKey}' \\
  --json '
  ${JSON.stringify(json, null, 4)}' \\
  ${url}`

const CurlExample = ({json, apiKey, url}) => {
  const example = curlExample(json, apiKey, url);
  return (
    <div className="curl">
      <pre>
      <CopyButton className="float-end" textToCopy={example} id={"id-json-example"}/>
      <div className="p-2" dangerouslySetInnerHTML={{
        __html: hljs.highlight('$ ' + example, {language: 'bash'}).value
      }}></div>
    </pre>
    </div>

  );
}

export default PostingInstructions;
