// There are unlikely to change any time soon, so hard-coding
// for crafting example JSONs are fine.
const piiFields = [
  'first_name',
  'last_name',
  'address',
  'address2',
  'phone',
  'email',
];

const getPing = (token, schema) => {
  return {
    source_token: token,
    data: schema,
  }
}

const getPingPost = (schema) => {
  let j = {
    "data": {},
    "accepted_bid_ids": [
      1,
    ],
    "accepted_buyers": [
      "Acme Enterprises",
    ],
  }

  if (!schema) return j;

  Object.keys(schema).map(key => {
    if (piiFields.includes(key)) {
      j.data[key] = schema[key]
    }
  })

  return j
};

const ResponseScenarios = {
  ping: [
    {
      label: 'Ping Success',
      description: `This ping returned 2 bids, one exclusive and one shared. You are free to accept either bid by including the bid_id in the accepted_bids of your POST request. If you don't specify accepted_bids, the bids in default_accepted_bid_ids will be accepted by default. In most cases this is what you want.`,
      data: {
        "auction_id": "1b531973-36d2-44cc-9af2-dd32c1333c64",
        "bids":
          [
            {
              "bid_id": 1,
              "buyer_name": "Acme Enterprises",
              "exclusive": true,
              "bid_amount": 3.3,
              "sold": null,
            },
            {
              "bid_id": 2,
              "buyer_name": "MegaCorp",
              "exclusive": false,
              "bid_amount": 1.2,
              "sold": null,
            }
          ],
        "expires_at": "2024-12-19T23:30:50.95226922Z",
        "payout": 0,
        "sold": null,
        "matched": true,
        "bid_amount": 3.3,
        "default_accepted_bid_ids": [
          1
        ],
        "time_ms": 324,
        "data_issues": {}
      }
    },
    {
      label: 'Ping – Unmatched',
      description: `This ping did not return any bids. This could mean that there are currently no buyers interested in this lead, or that there is information missing from the lead that our buyers target on. Make sure to submit as much information as possible to increase your chances of matching.`,
      data: {
        "auction_id": "1b531973-36d2-44cc-9af2-dd32c1333c64",
        "bids": [],
        "expires_at": "2024-12-19T23:30:50.95226922Z",
        "payout": 0,
        "sold": null,
        "matched": false,
        "bid_amount": 0,
        "default_accepted_bid_ids": [],
        "time_ms": 324,
        "data_issues": {}
      }
    },
    {
      label: 'Ping – Data Issues',
      description: `This ping contained data that triggered validation issues.`,
      data: {
        "auction_id": "1b531973-36d2-44cc-9af2-dd32c1333c64",
        "bids": [],
        "expires_at": "2024-12-19T23:30:50.95226922Z",
        "payout": 0,
        "sold": null,
        "matched": false,
        "bid_amount": 0,
        "default_accepted_bid_ids": [],
        "time_ms": 324,
        "data_issues": {
          "todo": "todo"
        }
      }
    }
  ],
  pingpost: [
    {
      label: 'Sale',
      data: {
        "auction_id": "1b531973-36d2-44cc-9af2-dd32c1333c64",
        "bids":
          [
            {
              "bid_id": 1,
              "buyer_name": "Acme Enterprises",
              "exclusive": true,
              "bid_amount": 3.3,
              "sold": true,
            },
            {
              "bid_id": 2,
              "buyer_name": "MegaCorp",
              "exclusive": false,
              "bid_amount": 1.2,
              "sold": false,
            }
          ],
        "expires_at": "2024-12-19T23:30:50.95226922Z",
        "payout": 3.3,
        "sold": true,
        "matched": true,
        "bid_amount": 3.3,
        "default_accepted_bid_ids": [
          1
        ],
        "time_ms": 324,
        "data_issues": {}
      }
    },
    {
      label: 'No Sale',
      data: {
        "auction_id": "1b531973-36d2-44cc-9af2-dd32c1333c64",
        "bids":
          [
            {
              "bid_id": 1,
              "buyer_name": "Acme Enterprises",
              "exclusive": true,
              "bid_amount": 3.3,
              "sold": false,
            },
            {
              "bid_id": 2,
              "buyer_name": "MegaCorp",
              "exclusive": false,
              "bid_amount": 1.2,
              "sold": false,
            }
          ],
        "expires_at": "2024-12-19T23:30:50.95226922Z",
        "payout": 0,
        "sold": false,
        "matched": true,
        "bid_amount": 3.3,
        "default_accepted_bid_ids": [
          1
        ],
        "time_ms": 324,
        "data_issues": {}
      }
    },
  ],
}

export const pingRequestSchema = {
  type: "object",
  properties: {
    source_token: {
      type: "string",
      description: "The source token for this traffic type",
    },
    data: {
      type: "object",
      description: "The lead data (refer to full schema on the left)",
    }
  }
}

export const pingPostRequestSchema = {
  type: "object",
  properties: {
    data: {
      type: "object",
      description: "The remaining lead data (PII)",
    },
    accepted_bid_ids: {
      type: "array",
      description: "(Optional) The ids of the bids you wish to sell to. If left empty, it will default to `default_accepted_bid_ids` from the ping response."
    },
    accepted_buyers: {
      type: "array",
      description: "(Optional) The names of the buyers you wish to sell to, as specified by the `buyer_name` property on each bid. If left empty, sales will be executed according to `default_accepted_bid_ids` from the ping response."
    }
  }
}

// also doubles as pingpost/post response schema
export const pingResponseSchema = {
  type: "object",
  properties: {
    auction_id: {
      type: "string",
      description: "The ID of the auction your ping created.",
    },
    bids: {
      type: "array",
      items: {
        properties: {
          bid_id: {
            type: "integer",
            description: "The ID of the bid. This is an increment within the auction: 1, 2, 3, etc..",
          },
          buyer_name: {
            type: "string",
            description: "The name of the entity who is buying the lead. For TPCA purposes, this is the DBA (doing business as) name.",
          },
          exclusive: {
            type: "boolean",
            description: "If true, the buyer is buying the lead exclusively and you cannot sell it to any other party. If false, it is a shared sale, and you are free to sell the lead to other parties. If your platform does not handle bid-level exclusivity, please use the exclusive: true/false flag on the PING to only obtain exclusive or shared leads in the response."
          },
          bid_amount: {
            type: "float",
            description: "The dollar amount of the bid."
          },
          sold: {
            type: "boolean",
            description:  "Whether this bid has successfully been sold to. On the ping response this field has a null value, because no sale decision has been made yet. After the POST, it is either true or false."
          }
        }
      }
    },
    expires_at: {
      type: "string",
      description: "The expiration time of this auction (in RFC3339 format). All posts received after this deadline are rejected.",
    },
    payout: {
      type: "float",
      description: "The total dollar payout of this auction. On ping response this is zero, because no sale decision has been made yet. On post this will be the total amount of all sold bids.",
    },
    sold: {
      type: "boolean",
      description: "Whether this auction had any sales. On the ping response this field has a null value, because no sale decision has been made yet. After the POST, it is either true or false.",
    },
    matched: {
      type: "boolean",
      description: "Whether this auction has any matches. This is a convenience property which is true when there are 1 or more bids, false otherwise."
    },
    bid_amount: {
      type: "float",
      description: "The total dollar bid amount of this auction, corresponding to the default_accepted_bid_ids array. If you specify your own accepted_bid_ids on the POST, this amount can change accordingly."
    },
    default_accepted_bid_ids: {
      type: "array",
      description: "The bid IDs of bids that we recommend selling to. You are free to submit a different bid selection, as long as it does not violate exclusivity constraints."
    },
    time_ms: {
      type: "float",
      description: "The time it took to process your request, in milliseconds. This is measured server-side and excludes time on the network."
    },
    data_issues: {
      type: "object",
      description: "Validation issues with the data you submitted.",
      properties: {
        unexpected_fields: {
          type: "array",
          description: "Fields that were submitted that are not part of our lead schema. This should help identify potential spelling mistakes in field names, or interacting with the wrong source."
        },
        invalid_fields: {
          type: "array",
          description: "Fields that had formatting issues (invalid dates, malformed phone numbers..). These fields are removed from the data before we process it."
        },
        required_fields: {
          type: "array",
          description: "Fields that are required but were not submitted. If they were submitted, please check invalid_fields whether they have been removed for formatting issues."
        }
      }
    }
  }
}

export {getPing, getPingPost, ResponseScenarios};
