import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TabPane,
  TabContent,
  Button,
} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import RulesContext from '../../../Providers/RulesContext';
import lazify from '../../../utils/Lazify';
import NavTabs from './NavTabs';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import Header from './Header';
import RuleSummary from './RuleSummary';
import useFormState from '../../../Hooks/useFormState';
import Settings from './Settings';
import Caps from './Caps';
import Tracking from './Tracking';
import ActionsPanel from './ActionsPanel';
import {PixelValidation, JSONValidation} from '../../../Components/Form/CustomValidation';
import { getFormattedNumber } from '../../../utils/numberFormat';
import usePermission from '../../../Hooks/usePermission';
import Advanced from './Advanced';
import Enrichments from "../../../App/AdvertiserManager/RulesManager/RulesConfig/Enrichments";
import DeliveryDestinations from "../../../Components/Rules/DeliveryDestinations"; // brings in all custom methods

// brings in all custom methods

// const LazyActivitySchedule = lazify( () => import(/* webpackChunkName: "ActivitySchedule" */ "../ActivitySchedule"));
const LazyTargetingManager = lazify(() => import(/* webpackChunkName: "TargetingManager" */ '../../../Components/TargetingManager'));

const ValidationSchema = Yup.object().shape({
  label: Yup.string()
    .trim()
    .required('Please enter a name for the rule'),
  // schedule: Yup.mixed().validSchedule('Please check that all dates are filled out, and from/to dates are in the right order'),
  // channel_routes: Yup.mixed().validChannelRoutes('Invalid Channel Routes'),
  bidding: Yup.object().shape({
    type: Yup.string(), //
    bid_modifier: Yup.mixed().when('type', { // note, error property will be 'bidding.bid_modifier'
      is: 'modifier',
      then: Yup.number().transform((n) => ((isNaN(n) || n === null) ? -1 : parseInt(n))).min(0, 'Bid adjustment is required').max(500, 'Maximum adjustment is 500'),
    }),
    absolute_bid: Yup.mixed().when('type', {
      is: 'absolute',
      then: Yup.number().transform((n) => ((isNaN(n) || n === null) ? -1 : n)).min(0, 'Bid is required').max(9999, 'Maximum bid is $99.99'),
    }),
  }),
  sale_caps: Yup.mixed().validRoutingCaps('All caps must have a timeframe and a cap value'),
  match_caps: Yup.mixed().validRoutingCaps('All caps must have a timeframe and a cap value'),
  ping_dedupe: Yup.mixed().validPingDedupeCaps('All ping dedupe caps must have an expression and a time value'),
  match_conditions: Yup.mixed().validTargeting('Invalid Targeting'),
  schedule: Yup.mixed().validActivitySchedule('Please check that all dates are filled out, and from/to dates are in the right order'),
  billable_call_duration: Yup.number().transform((n) => ((isNaN(n) || n === null) ? null : parseInt(n))).max(300, 'Maximum billable duration is 300 seconds').nullable(),
  click_tags: PixelValidation,
  internal_data: JSONValidation,
});

// Maps a tab to a validated field
const FormSectionToFieldMap = {
  summary: ['label'],
  targeting: ['match_conditions'],
  integration: ['enrichment_addons'],
  bidding: ['bidding.bid_modifier', 'bidding.absolute_bid', 'billable_call_duration'],
  caps: ['match_caps', 'sale_caps', 'ping_dedupe'],
};

const initializeRule = (rule) => {
  if (rule) {
    // empty budget or caps is array, expecting object
    if (Array.isArray(rule.match_caps)) rule.match_caps = {};
    if (Array.isArray(rule.sale_caps)) rule.sale_caps = {};

    if (rule.bidding.absolute_bid) {
      rule.bidding.absolute_bid = getFormattedNumber(rule.bidding.absolute_bid);
      // let strBid = rule.bidding.absolute_bid.toString();
      // let dotPos = strBid.indexOf('.');
      // if(dotPos > 0 && strBid.indexOf('.') === strBid.length - 2){
      //   rule.bidding.absolute_bid += '0';
      // }
    }

    if (rule.account_bid_floor) {
      rule.account_bid_floor = getFormattedNumber(rule.account_bid_floor);
    }

    // Internal data saved and edited as JSON string. Created a pretty-formatted string.
    if (rule.internal_data) {
      const data = JSON.parse(rule.internal_data);
      rule.internal_data = data ? JSON.stringify(data, null, 2) : '';
    }
  }
  return rule;
};

const RulesConfig = (props) => {
  const context = useContext(RulesContext);
  const allowAdvanced = usePermission('rules_advanced.edit');

  initializeRule(props.rule);

  // Rule state is in the form state as you edit, reference formValues as 'rule'
  const {
    formApi, formIsDirty, formValues: rule, formErrors, formErrorSections,
  } = useFormState(
    props.rule,
    ValidationSchema,
    null,
    FormSectionToFieldMap,
    { attributes: context.attributes, product: props.product }
  );

  const isNew = rule && rule.hasOwnProperty('isNew');

  // TODO: adjust this for scrolling within the modal
  const scrollToTop = (t = 0) => window.scrollTo({ top: t, behavior: 'smooth' });

  useEffect(() => {
    // Go to errored section if not on it already
    if (formErrorSections.length && !formErrorSections.includes(props.activeTab)) {
      props.onSelectTab(formErrorSections[0]);
      // TODO: scrollTOTop
    }
  }, [formErrorSections]);

  // Send dirty form state to the top because it affects rule tree
  useEffect(() => {
    context.api.setIsDirty(formIsDirty);
  }, [formIsDirty]);

  const handleCancel = () => {
    if (isNew) {
      // de-select (goes to previously selected)
      context.api.setSelected(null);
    } else {
      // reset current rule by reselecting with refresh
      context.api.setSelected(rule, 'summary', true);
      props.onSelectTab('summary');
    }
    scrollToTop();
    // react UUID: https://github.com/thearnica/react-uid
  };

  const handleSave = () => {
    const isValid = formApi.validate();
    if (isValid) {
      save();
    }
  };

  /* Process rule and call rule context save */
  const save = () => {
    const saveRule = { ...rule };

    saveRule.label = saveRule.label.trim();

    // Zip match condition comes in as array, must be saved as string.
    // If it's still an array (no interaction), stringify it
    if (saveRule.match_conditions) {
      saveRule.match_conditions = saveRule.match_conditions.map((group) => group.map((target) => {
        if (target.attribute === 'zipcode' && Array.isArray(target.value)) {
          target.value = target.value.join(',');
        }
        return target;
      }));
    }

    saveRule.schedule_type = rule.schedule.length > 0 ? 'inactive' : 'active';

    if (saveRule.internal_data && typeof saveRule.internal_data === 'object') {
      saveRule.internal_data = JSON.stringify(saveRule.internal_data);
      if (!saveRule.internal_data) {
        saveRule.internal_data = '';
      }
    }

    context.api.saveRule(saveRule, isNew);
  };

  return (
    <>

      <ActionsPanel
        rule={rule}
        isOpen={props.actionsOpen}
        isRootRule={props.isRoot}
        isDirty={formIsDirty}
        onChange={formApi.handleOnChange}
          /* onPasteRule={this.handlePasteRule}
          onDeleteRule={deleteRule} */
        onSaveRule={handleSave}
        allowAdvanced={allowAdvanced}
      />

      { !rule && (
      <div className="rule-none-selected">Please select a rule to configure</div>
      )}

      { rule && (

      <>

        <Header
          rule={rule}
          onChange={formApi.handleOnChange}
          errors={formErrors}
        />

        {rule.disabled && (
        <div className="disabled-notice mt-2">
          <StandardAlert color="danger" icon="alert" className="validation mb-1">
            This rule is disabled. The rule and any descendent rules will be ignored.
            <Button color="link" className="p-0 ms-2" onClick={() => context.api.disableRule(rule, false)}>Enable Rule</Button>
          </StandardAlert>
        </div>
        ) }

        {/* TODO: rule ancestors */}
        {/* {this.disabledAncestor  && <div className="disabled-notice">
                    <StandardAlert color="danger" icon="ale rt" className="validation mb-0">
                      The ancestor rule &quot;{this.disabledAncestor.label}&quot; has been disabled. This rule will not be applied.
                    </StandardAlert>
                  </div> } */}

        <NavTabs
          activeTab={props.activeTab}
          onSelectTab={props.onSelectTab}
          isSectionValid={formApi.isSectionValid}
          product={props.product}
          allowAdvanced={allowAdvanced}
        />

        <div className="rule-config thin-scrollbar">
          <TabContent activeTab={props.activeTab} className="rules-transition">

            <TabPane tabId="summary">

              <RuleSummary
                rule={rule}
                product={props.product}
                gotoTab={props.onSelectTab}
                allowAdvanced={allowAdvanced}
              />
            </TabPane>


            <TabPane tabId="integrations">

              <div className="form-section">
                <div className="form-section-header">Enrichment Integrations</div>
                <Enrichments
                  addOns={rule.enrichment_addons}
                  onChange={(sc) => {
                    formApi.handleOnChange('enrichment_addons', sc)
                  }}
                  errors={{}}
                  loadIntegrations={() => {
                    return context.api.getIntegrationDefinitionsByType('enrichment')
                  }}
                />
              </div>

              <DeliveryDestinations
                definitions={context.api.getIntegrationDefinitionsByType('delivery')}
                label="Post-Sale Integrations"
                description="Configure post-sale deliveries or web hooks."
                destinations={rule.delivery_destinations}
                onChange={(dd) => {
                  formApi.handleOnChange('delivery_destinations', dd)
                }}
                errors={{}}
              />

            </TabPane>

            <TabPane tabId="targeting">

              <LazyTargetingManager
                conditions={rule.match_conditions}
                onUpdate={(groups) => formApi.handleOnChange('match_conditions', groups)}
                attributes={context.attributes}
                attributeGroups={context.attributeGroups}
                errors={formErrors.match_conditions}
                className="routing"
              />

            </TabPane>

            <TabPane tabId="bidding">
              {/* This tab is for 'settings' which includes bidding, but we
                  have to call it 'bidding' because rule tree icons and rule summaries are configured to link to that id.
                   To handle this better we would need a mapping of tree icons to form sections,
                   or not hard code that id into tree icons or summaries
                   */}
              <Settings
                product={props.product}
                bidding={rule.bidding}
                bidFalloff={rule.bid_falloff}
                bidFloor={rule.account_bid_floor}
                flowControl={rule.flow_control}
                sellStrategy={rule.sell_strategy}
                billableCallDuration={rule.billable_call_duration}
                onChange={formApi.handleOnChange}
                errors={formErrors}
                isActiveTab={props.activeTab === 'settings'}
              />
            </TabPane>

            <TabPane tabId="caps" className="caps">
              <Caps
                saleCaps={rule.sale_caps}
                matchCaps={rule.match_caps}
                pingDedupes={rule.ping_dedupe}
                onChange={formApi.handleOnChange}
                errors={formErrors}
              />
            </TabPane>
            {/*
MST-1970 hide schedule tab, sows confusion and chaos

                <TabPane tabId="activity">

                  <LazyActivitySchedule
                  schedule={rule.schedule}
                  scheduleType={rule.schedule_type}
                  onChangeSchedule={(s)=>formApi.handleOnChange('schedule', s)}
                  onChangeScheduleType={(type)=>formApi.handleChange('schedule_type', type)}
                  timezone={props.timezone}
                  error={formErrors.schedule}

                />

                </TabPane> */}

            {props.product === 'clicks' && (
            <TabPane tabId="tracking">
              <Tracking
                clickTags={rule.click_tags}
                onChange={formApi.handleOnChange}
                errors={formErrors}
              />
            </TabPane>
            )}

            {allowAdvanced && (
              <TabPane tabId="advanced">
                <Advanced
                  internalData={rule.internal_data}
                  autoRedirect={rule.auto_redirect}
                  excludeProfitReporting={rule.exclude_profit_reporting}
                  uniqueLeadKey={rule.unique_lead_key}
                  matchLimit={rule.match_limit}
                  matchTimeout={rule.match_timeout}
                  onChange={formApi.handleOnChange}
                  errors={formErrors}
                />
              </TabPane>
            )}

          </TabContent>
        </div>

        <div className="footer d-flex align-items-center justify-content-end mt-2">
          {context.allowEdit && (
          <>
            { (formIsDirty || Object.entries(formErrors).length > 0) && (
            <div className="me-2  dirty-alert">
              <FaIcon icon="exclamation-triangle" color="#990000" size="sm" />
&nbsp;
              {Object.entries(formErrors).length > 0
                ? <span>Please correct errors before saving.</span>
                : <span>This rule has been modified. Save your changes or cancel.</span>}
            </div>
            )}

            {/*
                Only show cancel if editing a rule because its function is to back out of editing,
                    users might think it closes the modal
            */}
            {(isNew || formIsDirty)
            && (
              <Button
                size="sm"
                onClick={handleCancel}
                color="text"
                className="me-2"
              >
                Cancel
              </Button>
            )}

            <Button
              size="sm"
              onClick={handleSave}
              color="primary"
              disabled={false}
            >
              Save Rule
            </Button>


          </>
          )}

        </div>

      </>
      )}

    </>

  );
};

RulesConfig.propTypes = {
  rule: PropTypes.object,
  attributes: PropTypes.object,
  attributeLookup: PropTypes.object,
  product: PropTypes.string,
  timezone: PropTypes.string,
  isDirty: PropTypes.bool,
  isRoot: PropTypes.bool,
  activeTab: PropTypes.string,
  onSelectTab: PropTypes.func,
  actionsOpen: PropTypes.bool,
};

export default RulesConfig;
