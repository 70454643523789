import React, { useContext, useState, useEffect } from 'react';
import {
  Row, Card, Col, FormGroup, Label, Input, FormText, Button, FormFeedback, CardBody,
} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import copy from 'fast-copy';
import JSONPretty from 'react-json-prettify';
import { googlecode } from 'react-json-prettify/dist/themes';
import { useDebouncedCallback } from 'use-debounce';
import lazify from '../../../../utils/Lazify';
import RulesContext from '../../../../Providers/RulesContext';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components"
import Configuration from '../../../../Components/Rules/Configuration';
import { TargetGroupsDescription } from '../../../../Components/Rules/TargetDescription';
import usePermission from '../../../../Hooks/usePermission';
import {HelpPopper} from "@thedmsgroup/mastodon-ui-components";
import IconCheckbox from "../../../../Components/Form/IconCheckbox";

const LazyTargetingManager = lazify(() => import(/* webpackChunkName: "TargetingManager" */ '../../../../Components/TargetingManager'));

const timingChoices = {
  matching: 'Pre-Matching', // BC naming
  post_matching: 'Post-Matching',
  suppression: 'Pre-Sale',
}

/*
 * Component for entering one lead "enrichment" aka sale condition (service, priority, settings, suppression conditions)
 */
const EnrichmentForm = (props) => {
  const rulesContext = useContext(RulesContext);
  const [willDelete, setWillDelete] = useState(false);
  // Form modifies data in state, then rule will be updated on apply
  const [tempData, setTempData] = useState(copy(props.addOn));
  const allowEditIntegration = usePermission('integrations.edit');
  const allowEditOrder = usePermission('orders.edit');

  const getIntegrationLabel = (name) => {
    const config = props.config.find((conf) => conf.name === name);
    return config ? config.label : undefined;
  };

  // Reset form data state on close to current rule data
  // (this will be the changed data after apply, or unchanged data after cancel)
  useEffect(()=>{
    if (!props.editMode) {
      setTempData(copy(props.addOn))
    }
  }, [props.editMode])


  const handleApply = () => props.onApply(tempData);


  const handleCancel = () => {
    if (props.isNew) {
      props.onDelete(props.index);
    }
    props.onEditMode(-1);
  };

  const priorities = props.addOnCount > 1 ? [...Array(props.addOnCount)].map((item, index) => index + 1) : null;

  const handleChangeIntegration = (integration) => {
    setTempData({ ...tempData, integration })
  };

  const handleChangePriority = (e) => setTempData({ ...tempData, priority: e.target.value });

  const handleChangeTiming = (e) => setTempData({ ...tempData, timing: e.target.value });

  const handleChangeNotify = (name, val) => setTempData({ ...tempData, notify: val });

  const handleChangeConditions = (conditions) => setTempData({ ...tempData, suppression_conditions: conditions });

  const handleChangeVendorAttributes = useDebouncedCallback((attr) => {
    setTempData({ ...tempData, vendor_attributes: attr })
  }, 400);

  const hasErrors = Object.keys(props.errors).length > 0;

  return (
    <Card className={classnames('form-section', 'sales-condition', 'mb-3', { odd: props.index > 0 && props.index % 2 > 0 })} id={`sales-condition-${props.index.toString()}`}>
      <div className="form-section-header d-flex justify-content-between align-items-center">
        <div>
          { getIntegrationLabel(props.addOn.integration.name) || (
            <span>
            Enrichment {props.addOnCount > 1 && <span>{props.index + 1}</span>}
          </span>
          )}
        </div>


        <div className="actions">
          {willDelete === true && (
            <div className="delete-prompt">
              <i>Remove this integration?</i>
              <Button color="success" size="xs" onClick={() => { props.onDelete(props.index); }} className="ms-2 me-2">yes</Button>
              <Button color="danger" size="xs" onClick={() => { setWillDelete(false); }}>no</Button>
            </div>
          )}

          {props.isNew === false
            && willDelete === false
            && props.editMode === false
            && allowEditIntegration
            && (
              <>
                <Button
                  color="link"
                  className="btn-edit inline"
                  size="sm"
                  onClick={() => props.onEditMode(props.index)}
                  title="Edit"
                >
                  <FaIcon icon="edit" size="1x" />
                </Button>
                <Button
                  color="link"
                  className="btn-trash"
                  size="sm"
                  onClick={() => { setWillDelete(true); }}
                  title="Remove"
                >
                  <FaIcon icon="trash" size="1x" />
                </Button>
              </>
          )}

          {props.editMode  && (
            <Button
              color="link"
              className="btn-edit"
              size="sm"
              onClick={handleCancel}
              title="Cancel"
            >
              <FaIcon icon="times" size="sm" /> Cancel
            </Button>
          )}
        </div>

      </div>

      <CardBody>
        {hasErrors === true && (
          <StandardAlert color="info" className="validation">
            <div>This item has missing or invalid values</div>
          </StandardAlert>
        ) }
        <div className="detail-table">
          <div className="detail-rows">
            <Row className="detail-row">
              <Label sm={2}>Timing <TypeHelp /></Label>
              {/* <div className="description">
                &nbsp;
              </div> */}
              <Col sm={10} className="value">
                {props.editMode ? (
                  <FormGroup>
                    <Input
                      type="select"
                      name="timing"
                      value={tempData.timing}
                      onChange={handleChangeTiming}
                      invalid={!!props.errors.timing}
                    >
                      {Object.keys(timingChoices).map(key => <option value={key}>{timingChoices[key]}</option>)}
                    </Input>
                    <FormFeedback>{props.errors.integration}</FormFeedback>

                  </FormGroup>
                ) : (
                  <span>{timingChoices[props.addOn.timing] || '-invalid-'}</span>
                )}
              </Col>
            </Row>

            <Row className="detail-row">
              <Label sm={2}>Priority</Label>

              <Col sm={10} className="value">
                {props.editMode ? (
                  <>
                    {props.addOnCount > 1 ? (
                      <Input type="select" name="priority" value={tempData.priority} onChange={handleChangePriority}>
                        {priorities.map((val, i) => <option key={i} value={val}>{val}</option>)}
                      </Input>
                    ) : (
                      <span>1</span>
                    )}
                  </>
                ) : (
                  <span>{props.addOn.priority}</span>
                )}
              </Col>
            </Row>

            {/* MST-979 if user has no permission to edit integrations: hide if none exist, show read-only if integration exists */}

            <Row className="detail-row">
              <Label sm={2}>Integration</Label>
              <Col sm={10} className="mt-2">

                <div className="value">
                  <Configuration
                    type="enrichment"
                    definitions={props.config}
                    config={tempData.integration}
                    allowControls={false}
                    isMulti={false}
                    editMode={props.editMode && allowEditIntegration}
                    allowEdit={allowEditOrder}
                    errors={props.errors}
                    onUpdate={handleChangeIntegration}
                    onDelete={() => {}}
                    index={0}
                  />
                </div>

              </Col>
            </Row>

            <Row className="detail-row">
              <Label sm={2}>Suppression Conditions <HelpPopper
                name="conditions-type-help"
                iconClass="ms-1"
              >
                <p>
                  If condition evaluates to true, the match is suppressed.
                </p>
              </HelpPopper></Label>

              {/* TODO: notice when no conditions exist yet. Put here or in summary component? */}
              <Col sm={10} className="value">
                {props.editMode ? (

                  <LazyTargetingManager
                    conditions={Array.isArray(tempData.suppression_conditions) ? tempData.suppression_conditions : []}
                    onUpdate={(c) => { handleChangeConditions(c); }}
                    attributes={rulesContext.attributes}
                    attributeGroups={rulesContext.attributeGroups}
                    errors={props.errors ? props.errors.suppression_conditions : null}
                  />

                ) : (
                  <div className="target-summary mt-2">
                    <TargetGroupsDescription
                      attributes={rulesContext.attributes}
                      groups={Array.isArray(tempData.suppression_conditions) ? tempData.suppression_conditions : []}
                      limit={24}
                    />
                  </div>
                )}
              </Col>
            </Row>

            <Row className="detail-row">
              <Label sm={2}>Vendor Attributes</Label>

              <Col sm={10} className="value">
                {props.editMode  ? (
                  <FormGroup className=" flex-fill mt-2">
                    <Input
                      type="textarea"
                      rows={2}
                      defaultValue={tempData.vendor_attributes || ''}
                      name="vendor_attributes"
                      onChange={(e) => { handleChangeVendorAttributes(e.target.value); }}
                      invalid={!!props.errors?.vendor_attributes}
                    />
                    <FormText>Enter a valid JSON expression</FormText>

                  </FormGroup>
                ) : (
                  <div className="d-inline-block mt-1 json-pretty">
                    {props.addOn.vendor_attributes ? (
                      <JSONPretty json={props.addOn.vendor_attributes} theme={googlecode} />
                    ) : <i>none</i>}
                  </div>
                )}
              </Col>
            </Row>

            <Row className="detail-row">
              <Label sm={2}>Notify Only</Label>

              <Col sm={10} className="value">
                {props.editMode  ? (
                  <FormGroup className=" flex-fill mt-2">
                    <IconCheckbox
                      name="notify"
                      value={Boolean(tempData.notify)}
                      onChange={handleChangeNotify}
                    >
                      Notify Only
                    </IconCheckbox>
                    <FormText>By checking this box, we will not wait for this integration to return data.</FormText>
                  </FormGroup>
                ) : (
                  <div className="d-inline-block mt-1 json-pretty">
                    {props.addOn.notify ? "Yes" : "No"}
                  </div>
                )}
              </Col>
            </Row>

          </div>
        </div>

        {props.editMode && (
          <div className="text-end mt-2">
            {props.isNew === true ? (
              <>
                <Button size="sm" color="link" className="me-2" onClick={handleCancel} >Cancel</Button>
                <Button size="sm" onClick={handleApply}>Add</Button>

              </>
            ) : (
              <>
                <Button size="sm" color="link" className="me-2" onClick={handleCancel} outline>Cancel</Button>
                <Button size="sm" onClick={handleApply}>Apply</Button>
              </>
            )}
          </div>
        )}

      </CardBody>
    </Card>
  );
};

const TypeHelp = () =>
  <HelpPopper
    name="enrichment-type-help"
    iconClass="ms-1"
  >
    <p>
      <b>Pre-Matching</b> executes early during matching, BEFORE the match conditions are evaluated. This allows you to
      obtain data you want to use in matching decisions. You probably don't have PII available at this stage.
    </p>
    <p>
      <b>Post-Matching</b> executes AFTER the match conditions are evaluated and resulted in a match. Same as with Pre-Matching, you probably don't have PII available at this stage.
    </p>
    <p>
      <b>Pre-Sale</b> executes before selling. You have PII available at this stage.
    </p>
  </HelpPopper>


EnrichmentForm.propTypes = {
  addOn: PropTypes.object,
  addOnCount: PropTypes.number,
  allowControls: PropTypes.bool,
  config: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired, // configuration for all available integrations
  editMode: PropTypes.bool,
  onEditMode: PropTypes.func,
  errors: PropTypes.object,
  index: PropTypes.number,
  isNew: PropTypes.bool,
  onApply: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

EnrichmentForm.defaultProps = {
  addOn: {},
  addOnCount: 0,
  config: [],
  editMode: false,
  errors: {},
  index: 0,
  isNew: false,
};

export default EnrichmentForm;
