import BaseAPI from "../../../Services/BaseAPI";

class InputSchemaApi extends BaseAPI {
  constructor() {
    super(process.env.API_URL_CORE);
  }

  list = ()     => this.makeRequest('get', `${this.url}/input-schemas`);
  show = (id, params)    => this.makeRequest('get', `${this.url}/input-schemas/${id}`, params);
  update = (id, params) => this.makeRequest('put', `${this.url}/input-schemas/${id}`, params);
  create = (params) => this.makeRequest('post', `${this.url}/input-schemas`, params);
}

export default InputSchemaApi
