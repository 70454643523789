import React, { useEffect, useState } from 'react';
import {
  Button, Modal, ModalHeader
} from 'reactstrap';
import lazify from '../../../utils/Lazify';
import PageSection from '../../../Layout/PageSection';
import SourcesTable from './SourcesTable';
import Source from '../Source';
import Vendors from "../Source/Vendors";
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import FilterBar from '../../../Components/Table/FilterBar';
import Filters from './Filters';
import DocTitle from '../../../Layout/DocTitle';
import usePagedTableState from '../../../Hooks/usePagedTableState';
import useApi from '../../../Hooks/useApi';
import usePermission from '../../../Hooks/usePermission';
import RoutingRulesModal from '../../Routing/RulesManager/Modal';
import RoutingMapModal from "../../Routing/Mapper/Modal";

const LazyChangeLog = lazify(() => import(/* webpackChunkName: "ChangeLog" */ '../../ChangeLog/EntityChangeLog'));

const DefaultFilters = {
};

const DefaultControls = {
  limit: 50,
  sort_by: 'name',
  sort_dir: 'asc',
};

/*
 * Sources List - paged table with filters
 *
 */
const SourceList = (props) => {
  const {
    filters, setFilters, controls, updateQuery,
  } = usePagedTableState(
    DefaultFilters,
    DefaultControls,
    { useQueryString: true, useTableControls: true },
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [sourceModalOpen, setSourceModalOpen] = useState(false);
  const [rulesModalOpen, setRulesModalOpen] = useState(false);
  const [vendorModalOpen, setVendorModalOpen] = useState(false);
  const [selectedSource, setSelectedSource] = useState(null);
  const [changeLogKey, setChangeLogKey] = useState(1);
  const [routingMapId, setRoutingMapId] = useState(null);
  const apiList = useApi('sources', 'list');
  const apiUpdate = useApi('sources', 'update');
  const allowView = usePermission('sources.view');
  const allowCreate = usePermission('sources.create');
  const allowEdit = usePermission('sources.edit');
  const allowChangeLog = usePermission('changes.view');

  useEffect(() => {
    if (!loading) {
      load();
    }
  }, [filters, controls.sort_by, controls.sort_dir, controls.page, controls.limit]);

  const openRoutingModal = (sourceId) => setRoutingMapId(sourceId);
  const closeRoutingModal = () => setRoutingMapId(null);
  const closeVendorModal = (refresh) => {
    if (refresh === true) {
      load();
    }
    setVendorModalOpen(false);
  }

  const load = async () => {

    setLoading(true);
    updateQuery();

    const {
      page, sort_by, sort_dir, limit,
    } = controls;
    const result = await apiList.fetch({
      ...filters,
      page,
      sort_by,
      sort_dir,
      limit,
    });

    if (result) {
      const { limit, total, data } = result; // row:0, page:1, limit:20, data:[]
      setData(data);
      setPageCount(total > limit ? Math.ceil(total / limit) : 1);
      setTotalRows(total);
    } else {
      notify(`Unable to load sources: ${apiList.error?.name}`, 'error');
    }

    setLoading(false);
  };

  const closeSourceModal = (refresh = false) => {
    setSelectedSource(null);
    setSourceModalOpen(false);
    if (refresh === true) {
      load();
      setChangeLogKey(changeLogKey + 1);
    }
  };

  const openSourceModal = (source) => {
    if (!source) {
      source = {
        id: 0,
        name: '',
        description: '',
        product: '',
        vertical: { id: 0, name: '' },
        vendor: { id: 0, name: '' },
        phone_numbers: [],
        required_fields: [],
      };
    }
    setSelectedSource(source);
    setSourceModalOpen(true);
  };

  const openVendorModal = (source) => {
    setSelectedSource(source);
    setVendorModalOpen(true);
  };

  const openRulesModal = (source) => {
    setSelectedSource(source);
    setRulesModalOpen(true);
  };

  const closeRulesModal = () => setRulesModalOpen(false);

  const handleStatusChange = async (source, status) => {
    if (source && allowEdit) {
      // send status along with required form fields
      const result = await apiUpdate.fetch(
        {
          id: source.id,
          name: source.name,
          product: source.product,
          vertical_id: source.vertical.id,
          status,
        },
      );
      if (result) {
        // update the existing list data with the inline change
        setData((prev) => prev.map((src) => (src.id === source.id ? result : src)));
      } else {
        notify(`Unable to update status: ${apiUpdate.error?.name}`, 'error');
      }
      return Boolean(result);
    }
    return false;
  };

  if (!allowView) return (null);

  return (
    <>
      <DocTitle pageTitle="Sources" />

      <FilterBar>
        <Filters filters={filters} onChange={setFilters} onRefresh={() => load()} loading={loading} />
      </FilterBar>

      <PageSection
        title="Sources"
        className="overflow-section"
        tools={allowCreate === true ? (
          <Button
            className="ms-auto"
            onClick={() => openSourceModal()}
            color="primary"
            size="sm"
          >
            New Source
          </Button>
        ) : (null)}
      >

        <SourcesTable
          data={data}
          loading={loading}
          pageCount={pageCount}
          totalRows={totalRows}
          showObject={false}
          {...controls}
          onStatusChange={handleStatusChange}
          openEditModal={openSourceModal}
          openRulesModal={openRulesModal}
          openRoutingModal={openRoutingModal}
          openVendorModal={openVendorModal}
        />

        {selectedSource && (
          <RoutingRulesModal
            entityType="source"
            entity={selectedSource}
            isOpen={rulesModalOpen}
            close={closeRulesModal}
          />

        )}

        <Modal isOpen={sourceModalOpen} toggle={closeSourceModal} size="xlg" id="edit-source-modal" scrollable>
          {selectedSource && (
            <>
              <ModalHeader toggle={closeSourceModal}>
                {selectedSource.id === 0 ? 'Create Source' : (
                  <>
                    Edit Source | <span className="modal-subheader">{selectedSource.name}</span>
                  </>
                )}
                {' '}
              </ModalHeader>

                <Source
                  source={selectedSource}
                  closeModal={closeSourceModal}
                />


            </>
          )}

        </Modal>

        {selectedSource && (
            <Vendors
              source={selectedSource}
              isOpen={vendorModalOpen}
              close={closeVendorModal}
            />
        )}


      </PageSection>

      {allowChangeLog === true && (
      <LazyChangeLog
        entityType="source"
        refreshKey={changeLogKey}
      />
      )}

      <RoutingMapModal
        isOpen={Boolean(routingMapId)}
        toggle={closeRoutingModal}
        sourceId={routingMapId}
      />

    </>
  );
};

export default SourceList;
